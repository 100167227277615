import React from 'react';
import classnames from 'classnames';
import proptypes from 'prop-types';

/**
 * React wrapper for the native `<a>` element. 
 * 
 * Use `NewTab` for links that should open in new tab. (target = _blank)
 * 
 * Will spread all other attributes to element.
 * 
```javascript

import A from '../Anchor';

<A className="c-link" href="http://www.example.com" NewTab={true}>
  a link text
</A>

```

Will output 

```html
<a class="c-link" href="http://www.example.com" target="_blank" rel="noopener">
  a link text
</a>
```

 */
const A = (props) => {
  const { NewTab, className, children, hasIcon, buttonStyling, ...rest } = props;

  const attr = { ...rest };

  if (NewTab) {
    if (!attr.rel) attr.rel = '';
    attr.rel += ' noopener ';
    attr.target = '_blank';
  }

  return (
    <a
      className={classnames('c-anchor', className, {
        'c-anchor__button': buttonStyling,
      })}
      {...attr}
    >
      {children}
      {hasIcon ? <i className="icon-arrow-rigt-ext-circle"></i> : null}
    </a>
  );
};

A.defaultProps = {
  className: '',
};

A.propTypes = {
  /** Default: `c-anchor` */
  className: proptypes.string,
  /** If true it will set `target="_blank"` and `rel="noopener"` */
  NewTab: proptypes.bool,
  /** Link relationship */
  rel: proptypes.string,
  /** hyper-reference */
  href: proptypes.string,
  /** Icon to indicate external link */
  hasIcon: proptypes.bool,
  /** Button styling */
  buttonStyling: proptypes.bool,
};

export default A;
