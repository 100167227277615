import React from 'react';
import classNames from 'classnames';

const Item = (item) => {
  const classes = classNames({
    'c-toppnav__item': true,
    'c-toppnav__item--is-selected': item.isOpened || item.ActivePath,
    'c-toppnav__item--is-opened': item.isOpened,
  });

  let attributes = {
    href: item.Url,
    onClick: (e) => item.handleClick(e, item),
  };

  if (item.Children) {
    attributes = {
      href: item.Url,
      onClick: (e) => item.handleClick(e, item),
      'aria-expanded': item.isOpened,
      'aria-controls': `subnav-${item.PageId}`,
      'data-cy': 'ui-top-navigation-expand-link'
    };
  }

  return (
    <li className={classes}>
      <a {...attributes}>
        <span>{item.Text}</span>
      </a>
      {item.children}
    </li>
  );
};

export default Item;
