import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import T from 'prop-types';
import scrollIt from '../Base/Scroll';

const ScrollToTop = (props) => {
  let ticking = false;
  const [isOffsetTop, setIsOffestTopState] = useState(false);
  const [isFooterInWindow, setIsFooterInWindow] = useState(false);

  function animateScrollToTop(e) {
    e.preventDefault();
    scrollIt(0, 300, 'easeOutQuad', () => {});
    document
      .getElementsByClassName('c-header')[0]
      ?.getElementsByTagName('a')[0]
      ?.focus();
  }

  function setIsOffsetTop() {
    const footer = document.querySelector('.c-footer');
    const windowHeight = window.innerHeight - 25;

    if (
      windowHeight >= footer.getBoundingClientRect().top &&
      !isFooterInWindow
    ) {
      setIsFooterInWindow(true);
    }

    if (windowHeight < footer.getBoundingClientRect().top && isFooterInWindow) {
      setIsFooterInWindow(false);
    }

    if (window.pageYOffset >= 200 && !isOffsetTop) {
      setIsOffestTopState(true);
    }

    if (window.pageYOffset < 200 && isOffsetTop) {
      setIsOffestTopState(false);
    }
  }

  function throttleScroll() {
    ticking =
      ticking ||
      requestAnimationFrame(() => {
        ticking = false;
        setIsOffsetTop();
      });
  }

  useEffect(() => {
    window.addEventListener('scroll', () => throttleScroll());
  }, [isOffsetTop, isFooterInWindow]);

  const { Translations } = props.Content;

  const classes = classNames({
    'c-scroll-to-top': true,
    'c-scroll-to-top--visible': isOffsetTop,
    'c-scroll-to-top--footer-is-visible': isFooterInWindow,
    'c-scroll-to-top--vardpersonal': props.FeatureToggleSettings?.Use1177ProfessionDesign,
  });

  return (
    <a
      href="#"
      aria-label={Translations && Translations.toplink && Translations.toplink}
      onClick={(e) => {
        animateScrollToTop(e);
      }}
      className={classes}
      tabIndex={isOffsetTop ? '0' : '-1'}
    >
      {Translations && Translations.toplink && (
        <span className="show-for-sr">{Translations.toplink}</span>
      )}
    </a>
  );
};

ScrollToTop.propTypes = {
  Content: T.shape({
    Translations: T.shape({
      toplink: T.string,
    }),
  }),
};

export default ScrollToTop;
