import React from 'react';
import * as Render from '../Base/Render';
import classNames from 'classnames';
import T from 'prop-types';

const SurveyEntry = (props) => {
  return (
    <div className="row">
      <div className="surveyentry">
        {(props.Heading || props.ShowIcon) && (
          <div className="surveyentry__heading__container">
            {props.ShowIcon &&
              Render.Property(
                <i className="surveyentry__icon"></i>,
                'ShowIcon'
              )}
            {props.Heading &&
              Render.Property(
                <h2 className="surveyentry__heading">{props.Heading}</h2>,
                'Heading'
              )}
          </div>
        )}
        {props.MainBody && props.LinkUrl && props.LinkText && (
          <div
            className={classNames({
              surveyentry__container: true,
              'surveyentry__container--no-icon': !props.ShowIcon,
            })}
          >
            <div className="surveyentry__content__container">
              {Render.PropertyAsHTML(props.MainBody, 'MainBody')}
            </div>
            <a className="surveyentry__link" href={props.LinkUrl}>
              {Render.Property(props.LinkText, 'LinkText')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

SurveyEntry.propTypes = {
  ShowIcon: T.bool,
  Heading: T.string,
  MainBody: T.string,
  LinkUrl: T.string,
  LinkText: T.string,
};

export default SurveyEntry;
