import React from 'react';
import { node } from 'prop-types';

const AccessibilityNav = (props) => {
  return <ul className="c-accessibility-nav">{props.children}</ul>;
};

AccessibilityNav.propTypes = {
  /** List items (<li>) to be displayed in the accessability navigation */
  children: node.isRequired,
};

export default AccessibilityNav;
