export const loadScript = (id, src, done) => {
  if (typeof document === 'undefined') return;

  const alreadyLoaded = document.getElementById(id);

  if (alreadyLoaded) {
    done();
    return;
  }

  var js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.onload = () => {
    done();
  };
  js.onerror = () => {
    done(new Error('Failed to load script ' + src));
  };
  console.log('About to dynamically load ' + src);
  document.head.appendChild(js);
};
