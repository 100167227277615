import React from 'react';

const SemanticHeader = ({ isH3, isH4, children, ...props }) =>
  isH3 ? (
    <h3 {...props}>{children}</h3>
  ) : isH4 ? (
    <h4 {...props}>{children}</h4>
  ) : (
    <h2 {...props}>{children}</h2>
  );

export default SemanticHeader;
