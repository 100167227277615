import React, { useState, useEffect } from 'react';
import * as Cookies from '../Base/Cookies';
import classNames from 'classnames';

const CookiePanel = (props) => {
  const [isVisible, setIsVisible] = useState();
  const [isAccepted, setIsAccepted] = useState();

  useEffect(() => {
    setIsVisible(
      Cookies.parseCookieData('acceptedCookie', props.cookies) === null
    );
  }, []);

  const closeCookieHandler = (e) => {
    e.preventDefault();
    Cookies.createCookie('acceptedCookie', true, 365);
    setIsAccepted(true);

    setTimeout(() => {
      setIsAccepted(true);
    }, 2500);
  };

  if (!isVisible || !props || !props.CookieConsent) {
    return null;
  }
  const { CookieConsentText, CookieButtonText } = props.CookieConsent;

  const classes = classNames({
    'cookie-panel': true,
    'cookie-panel--is-closed': isAccepted,
  });

  return (
    <div className={classes}>
      <div className="cookie-panel__content">
        <div
          className="cookie-panel__content__text"
          dangerouslySetInnerHTML={{ __html: CookieConsentText }}
        ></div>
        <button
          className="cookie-panel__button"
          type="button"
          onClick={closeCookieHandler}
        >
          {CookieButtonText}
        </button>
      </div>
    </div>
  );
};

export default CookiePanel;
