import React, { memo } from 'react';

const staticComponent = (WrappedComponent, id) => {
  const StaticComponent = (props) => {
    const html =
      typeof window !== 'undefined' && document.getElementById(id)
        ? document.getElementById(id).innerHTML
        : null;

    if (html) {
      return <div id={id} dangerouslySetInnerHTML={{ __html: html }}></div>;
    }

    if (!WrappedComponent) return null;

    return (
      <div id={id}>
        <WrappedComponent id={id} {...props} />
      </div>
    );
  };

  memo(StaticComponent, () => {
    return false;
  });

  return StaticComponent;
};

export default staticComponent;
