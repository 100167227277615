export const createCookie = (name, value, days) => {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  } else expires = '';
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const parseCookieData = (name, cookieData) => {
  if (!cookieData) return null;
  if (typeof cookieData.split === 'undefined') return null;
  var nameEQ = name + '=';
  var ca = cookieData.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const readCookie = (name) => {
  if(typeof window === 'undefined'){
    return null;
  } 
  return parseCookieData(name, document.cookie)};

export const eraseCookie = (name) => createCookie(name, '', -1);
