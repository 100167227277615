import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PanelContext from './panelContext';
import HoverModal from '../Modal/hover-modal';

const CollapsiblePanel = (props) => {
  const panelContext = useContext(PanelContext);

  const [isCollapsed, setIsCollapsed] = useState(
    props.DefaultCollapsed === false ? false : true
  );

  const handleClick = () => {
    const { setActivePanelId } = panelContext;
    const { isAccordion, Id } = props;

    if (isAccordion) {
      setActivePanelId(Id);
      setIsCollapsed(!setIsOpen());
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  const setIsOpen = () => {
    const { activePanelId } = panelContext;
    const { isAccordion, Id } = props;

    if (isAccordion) {
      return Boolean(isCollapsed && Id === activePanelId);
    }
    return !isCollapsed;
  };

  if (!props) return null;

  const isOpen = setIsOpen();

  const componentClassname = props.className;

  const componentClasses = classNames({
    [componentClassname]: true,
    [`${componentClassname}--is-expanded`]: isOpen,
    [`${componentClassname}--has-hovereffect`]: props.hasHover,
  });

  const buttonAttributes = {
    onClick: () => handleClick(),
    type: 'button',
    'aria-controls': props.Id,
    'aria-expanded': isOpen,
    className: `${componentClassname}__button`,
    id: `btn-${props.Id}`,
  };

  const panelAttributes = {
    id: props.Id,
    'aria-hidden': !isOpen,
    'data-cy': 'collapsible-panel',
  };

  const panelClasses = classNames({
    [`${componentClassname}__panel`]: true,
    [`${componentClassname}__panel--is-expanded`]: isOpen,
  });

  return (
    <div className={componentClasses} dir={props.Dir}>
      <button {...buttonAttributes}>
        {props.hasHover && (
          <HoverModal
            ElementId={`btn-${props.Id}`}
            Heading={props.Heading}
            Description={props.Description}
            isHidden={isOpen}
          />
        )}
        <span
          className={`${componentClassname}__button__inner`}
          lang={props.Language}
        >
          {props.Heading}
          <span className={`${componentClassname}__button__icon-container`}>
            <span className="show-for-sr">
              {props.ExpandBtnText} {props.Heading}
            </span>
            <span
              className={classNames({
                [`${componentClassname}__button__icon`]: true,
                [`${componentClassname}__button__icon--is-expanded`]: isOpen,
              })}
            ></span>
          </span>
        </span>
      </button>
      <div {...panelAttributes} className={panelClasses}>
        {props.children}
      </div>
    </div>
  );
};

CollapsiblePanel.propTypes = {
  /** BEM model name (used to pixes cn__button, cn__panel).  */
  className: PropTypes.string,
  DefaultCollapsed: PropTypes.bool,
  children: PropTypes.node,
  Id: PropTypes.string,
  Heading: PropTypes.node,
  ExpandBtnText: PropTypes.node,
  isAccordion: PropTypes.bool,
  hasHover: PropTypes.bool,
  Dir: PropTypes.oneOf(['rtl', 'ltr', 'auto']),
};

export default CollapsiblePanel;
