import React from 'react';
import classNames from 'classnames';

const LangLink = (props) => {
  if (!props.Href) {
    return (
      <div className="c-langselector__item__disabled">{props.children}</div>
    );
  }

  return <a href={props.Href}>{props.children}</a>;
};

const LangSelectorLangList = ({ Languages }) => {
  return (
    <ul className="c-langselector">
      {Languages.map((lang, key) => (
        <li
          key={key}
          className={classNames({
            'c-langselector__item': true,
            'c-langselector__item--is-selected': lang.Selected,
          })}
        >
          <LangLink {...lang}>
            <span className="c-langselector__item__lang" dir={lang.Dir}>
              {lang.Name}
            </span>
            <span className="c-langselector__item__lang--sv">
              {lang.NameSwe}
            </span>
          </LangLink>
        </li>
      ))}
    </ul>
  );
};

export default LangSelectorLangList;
