import React from 'react';

const Property = (property, propertyName) => {
  if (!global.PWTPlatform.IsEditMode) return property;

  const editAttributes = {
    [global.PWTPlatform.EditAttributes[0]]: propertyName,
    [global.PWTPlatform.EditAttributes[1]]: true,
  };

  return (
    <span {...editAttributes} style={{ display: 'block' }}>
      {property}
    </span>
  );
};

const PropertyAsHTML = (property, propertyName) => {
  if (!global.PWTPlatform.IsEditMode && !property) return null;

  if (!global.PWTPlatform.IsEditMode)
    return <div dangerouslySetInnerHTML={{ __html: property }}></div>;

  const editAttributes = {
    [global.PWTPlatform.EditAttributes[0]]: propertyName,
    [global.PWTPlatform.EditAttributes[1]]: true,
  };

  return (
    <div
      {...editAttributes}
      dangerouslySetInnerHTML={{ __html: property }}
    ></div>
  );
};

export { Property, PropertyAsHTML };
