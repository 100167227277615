const initialState = {};

const appendSearchHits = (state, action) => {
  //Remove or add SearchHits
  if (
    (action.payload.NextPage && state.NextPage > action.payload.NextPage) ||
    !state.NextPage
  ) {
    const index = state.SearchHits.length - action.payload.SearchHits.length;
    //patch browser
    if (index < 1) return [...action.payload.SearchHits];

    state.SearchHits.length = index;
    return [...state.SearchHits];
  }

  return [...state.SearchHits, ...action.payload.SearchHits];
};

export const search = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOADING':
      return Object.assign({}, state, {
        isLoading: true,
      });

    case 'LOAD_MORE':
      return Object.assign({}, state, {
        ...action.payload,
        isLoading: false,
        SearchHits: appendSearchHits(state, action),
      });
    case 'FILTER':
      return Object.assign({}, action.payload, {
        isLoading: false,
      });
    case 'AUTOCOMPLETE':
      return Object.assign({}, state, {
        Hits: action.payload ? action.payload : [],
        isVisible: action.payload !== null,
        currentIndexObject: action.currentIndexObject,
        isLoading: false,
        error: false,
      });
    case 'HIDE_AUTOCOMPLETE':
      return Object.assign({}, state, {
        isVisible: false,
      });
    case 'OPTION_INDEX_AUTOCOMPLETE':
      return Object.assign({}, state, {
        index: action.index,
        value: action.selectedValue,
        currentIndexObject: action.currentIndexObject,
        isVisible: true,
      });
    case 'OPTION_SELECT_AUTOCOMPLETE':
      return Object.assign({}, state, {
        value: action.selectedValue,
        selectedObject: action.selectedObject,
        currentIndexObject: action.currentIndexObject,
        isVisible: false,
      });
    case 'ONCHANGE':
      return Object.assign({}, state, {
        index: action.index,
        value: action.value,
        Hits: action.Hits,
        selectedObject: action.selectedObject,
      });
    case 'SEARCH':
      return Object.assign({}, action.payload, {
        isLoading: false,
      });
    case 'ERROR':
      return Object.assign({}, action.payload, {
        isLoading: false,
        error: true,
      });
    default:
      return state;
  }
};
