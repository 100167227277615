import React from 'react';
import T from 'prop-types';
import FallbackComponent from './fallback-component';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
      error: { message: '', stack: '' },
      info: { componentStack: '' },
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    });

    this.log(error, info);
  }

  log(error, info) {
    console.group('Component error');
    console.error(error);
    console.error(info);
    console.groupEnd();
  }

  render() {
    if (this.state.hasError) {
      if (typeof this.props.renderFallback === 'function') {
        return this.props.renderFallback(this.props);
      }
      return (
        <FallbackComponent
          title="Något gick fel i errorBoundry"
          message="Jahopp..."
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  renderFallback: FallbackComponent,
};

ErrorBoundary.propTypes = {
  renderFallback: T.func,
};

export default ErrorBoundary;
