import React from 'react';
import classNames from 'classnames';
import T from 'prop-types';

const Spinner = (props) => {
  const spinnerClasses = classNames({
    'c-spinner': true,
    'c-spinner--alt': props.alt,
    ...props.Modifiers,
  });

  return (
    <span className={spinnerClasses}>
      <span className="bounce1"></span>
      <span className="bounce2"></span>
      <span className="bounce3"></span>
    </span>
  );
};

Spinner.propTypes = {
  /** For use with `classnames` package */
  Modifiers: T.object,
  alt: T.bool,
};

export default Spinner;
