import React from 'react';
import ReadingAid from '../../platformui/ReadingAid';
import Spinner from '../../platformui/Spinner';
import { LangSelector } from '../c-langselector';

const YoumoTools = (props) => {
  return (
    <ul className="c-header-toolsy">
      <li className="c-header-toolsy__item c-header-toolsy__item--reading-aid">
        <ReadingAid
          {...props}
          Spinner={Spinner}
          ButtonSVG={
            <div className="c-header-toolsy__reading-aid c-header-toolsy__reading-aid__background"></div>
          }
          toggleButtonClasses={[
            'c-header-tools__toolbtn',
            'c-header-toolsy__reading-aid__toggle-panel',
          ].join(' ')}
          activateButtonClass={'c-forms__button'}
        />
      </li>

      {props.umoYoumoConfig &&
        props.umoYoumoConfig.HideVisitUrl &&
        props.PageTypeName !== 'LanguagePage' && (
          <li className="c-header-toolsy__item c-header-toolsy__item--hide-visit show-for-large">
            <div className="c-header-toolsy__item__hide-background"></div>
            <a
            href={props.umoYoumoConfig.HideVisitUrl}
              className="c-header-toolsy__item__text c-header-toolsy__item__text--hide-visit"
            >
              <span className="c-header-toolsy__item__text__center">
              {props.umoYoumoConfig.HideVisitText}
              </span>
            </a>
          </li>
        )}

      {props.umoYoumoConfig && props.PageTypeName !== 'LanguagePage' && (
        <li className="c-header-toolsy__item c-header-toolsy__item--change-lang show-for-large">
          <LangSelector {...props} />
        </li>
      )}

      {props.umoYoumoConfig && (
        <li className="c-header-toolsy__item c-header-toolsy__item--umo show-for-large">
          <div className="c-header-toolsy__item__umo-logo-background"></div>
          <i className="c-header-toolsy__item__umo-logo">
            <a href={props.umoYoumoConfig.UmoUrl} aria-label="Umo" tabIndex="0">
              <span className="show-for-sr">{props.umoYoumoConfig.UmoText}</span>
            </a>
          </i>
        </li>
      )}
    </ul>
  );
};

export default YoumoTools;
