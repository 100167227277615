import React from 'react';

const LogoYoumo = (props) => {
  if (props.link) {
    return (
      <a href={props.link}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="104"
          height="28"
          viewBox="0 0 104 28"
        >
          <defs>
            <path id="a" d="M22.764 21.678H.011V.037h22.753v21.641z" />
            <path id="b" d="M.258 21.678h34.573V.038H.258z" />
            <path id="c" d="M23.08 21.678H.326V.037H23.08v21.641z" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              fill="#060504"
              d="M4.47 21.077h1.27c.543 0 .785-.208.997-1.07l1.178-4.336-2.841-7.248c-.272-.713-.514-1.01-1.149-1.01H3.17l.816-3.03h10.006l-.847 3.03h-.846c-.665 0-.968.505-.726 1.247l1.421 3.743 3.416-3.92c.544-.625.332-1.07-.363-1.07h-1.028l.816-3.03h8.495l-.816 3.03h-.726c-.695 0-1.149.297-1.814 1.01l-6.892 7.485-1.118 4.1c-.242.861-.152 1.07.423 1.07h1.27l-.817 3.03H3.623l.846-3.03z"
            />
            <path
              fill="#060504"
              d="M8.114 7.51l.211.537c.006-.182.024-.361.053-.537h-.264zM10.96 14.8c.027.09.049.182.067.274.017-.06.035-.119.056-.177a3.032 3.032 0 0 1-.123-.097zm73.347 10.552a3.157 3.157 0 0 1-2.04 1.761c-1.265.356-2.645.536-4.102.536-2.702 0-4.346-.834-5.343-1.9a3.18 3.18 0 0 1-2.698 1.484h-5.682a3.2 3.2 0 0 1-1.905-.627 3.194 3.194 0 0 1-1.904.627H54.83a3.21 3.21 0 0 1-.831-.11c-.036.01-.071.019-.107.027-1.043.242-2.283.499-3.807.499-1.995 0-3.488-.497-4.565-1.268-1.465.837-3.123 1.268-4.927 1.268-2.612 0-4.775-1.052-6.025-2.747-2.069 1.722-4.756 2.747-7.729 2.747-4.284 0-7.638-1.755-9.414-4.67l-.52 1.93a3.163 3.163 0 0 1-3.064 2.324H3.623c-.99 0-1.922-.456-2.521-1.232a3.093 3.093 0 0 1-.535-2.726l.847-3.03a3.155 3.155 0 0 1 2.591-2.262l.58-2.137-2.11-5.383A3.177 3.177 0 0 1 .658 9.319c-.6-.77-.803-1.77-.55-2.71l.815-3.03a3.163 3.163 0 0 1 3.064-2.323H13.99c.316 0 .627.047.922.135.294-.088.604-.135.923-.135h8.493c.985 0 1.913.451 2.513 1.22.6.77.804 1.771.55 2.71l-.225.84c.22-.011.444-.017.669-.017 2.954 0 5.47.804 7.342 2.225a3.173 3.173 0 0 1 2.875-1.81h3.355c1.453 0 3.098-.164 3.638-.282a3.202 3.202 0 0 1 2.804.728 3.196 3.196 0 0 1 1.63-.445h2.538c1.245 0 2.16-.13 2.565-.242a3.207 3.207 0 0 1 2.326.244l.127-.002h3.809c1.37 0 2.528-.146 3.154-.283a3.209 3.209 0 0 1 2.318.373 9.956 9.956 0 0 1 3.203-.506c1.753 0 3.282.477 4.458 1.322 1.484-.88 3.176-1.322 5.064-1.322 2.588 0 4.698.987 5.948 2.618 2.029-1.65 4.667-2.618 7.624-2.618 6.543 0 10.939 3.943 10.939 9.81 0 6.634-5.243 11.831-11.936 11.831-2.947 0-5.454-.83-7.31-2.297z"
              opacity=".2"
            />
            <path
              fill="#060504"
              d="M30.103 14.394c0-1.189-.635-2.05-1.904-2.05-2.328 0-3.507 5.02-3.507 6.891 0 1.248.605 2.08 1.935 2.08 2.327 0 3.476-5.05 3.476-6.921M19.191 17.69c0-4.872 3.567-8.555 8.645-8.555 4.534 0 7.769 2.317 7.769 6.684 0 4.901-3.719 8.703-8.767 8.703-4.503 0-7.647-2.466-7.647-6.832M46.366 21.374c-1.451 2.08-3.356 3.15-5.774 3.15-2.297 0-4.02-1.16-4.02-3.239 0-1.01.393-2.109.816-3.386l1.33-4.337c.151-.446.12-1.07-.332-1.07h-1.149l.816-2.94h3.355c1.633 0 3.507-.179 4.323-.357l-2.327 8.08c-.273.98-.575 1.99-.575 2.614 0 .683.423 1.1 1.118 1.1 2.056 0 3.537-3.863 5.532-11.438h2.54c1.45 0 2.66-.148 3.415-.356l-2.6 9.625c-.241.772-.423 1.425-.423 1.841 0 .565.303.743.726.743.242 0 .544-.06.786-.149l-.756 2.852c-1.027.238-1.965.416-3.083.416-2.418 0-3.749-1.01-3.749-2.911 0-.03 0-.119.03-.238M73.329 12.195c1.45-2.05 3.294-3.06 5.713-3.06 2.328 0 4.02 1.16 4.02 3.298 0 2.436-2.358 7.01-2.358 8.228 0 .535.273.713.696.713.241 0 .484-.059.785-.148l-.785 2.881c-1.058.297-2.146.416-3.235.416-2.358 0-3.687-.772-3.687-2.911 0-1.931 2.206-6.476 2.206-7.842 0-.683-.362-1.07-1.058-1.07-2.055 0-3.506 4.04-5.501 11.407H64.44c1.784-6.624 2.72-8.941 2.72-10.308 0-.712-.361-1.099-1.057-1.099-1.965 0-3.506 3.95-5.47 11.407h-5.805L57.7 13.562c.211-.832.12-1.07-.333-1.07H56.22l.815-2.94h3.81c1.632 0 3.022-.179 3.838-.357l-.906 3.149c1.48-2.11 3.325-3.208 5.743-3.208 1.632 0 3.658.713 3.809 3.06M94.881 14.394c0-1.189-.634-2.05-1.904-2.05-2.328 0-3.506 5.02-3.506 6.891 0 1.248.604 2.08 1.934 2.08 2.328 0 3.476-5.05 3.476-6.921M83.97 17.69c0-4.872 3.567-8.555 8.645-8.555 4.535 0 7.77 2.317 7.77 6.684 0 4.901-3.719 8.703-8.767 8.703-4.504 0-7.648-2.466-7.648-6.832"
            />
            <path
              fill="#FEFEFE"
              d="M4.503 20.035H5.78c.547 0 .79-.21 1.004-1.081l1.186-4.386-2.86-7.332c-.273-.72-.517-1.021-1.156-1.021h-.76l.82-3.065h10.072l-.853 3.065h-.851c-.67 0-.974.51-.73 1.262l1.43 3.785 3.437-3.966c.548-.63.335-1.081-.365-1.081h-1.034l.821-3.065h8.55l-.822 3.065h-.73c-.7 0-1.156.3-1.826 1.021l-6.937 7.572-1.125 4.146c-.243.871-.152 1.081.426 1.081h1.278l-.822 3.065H3.651l.852-3.065z"
            />
            <path
              fill="#FEFEFE"
              d="M8.065 6.319l.21.543a4.31 4.31 0 0 1 .052-.543h-.262zm2.828 7.368c.026.091.049.183.067.277.016-.06.035-.12.055-.18a3.021 3.021 0 0 1-.122-.097zm2.863 12.563H3.6c-.983 0-1.91-.46-2.506-1.245a3.167 3.167 0 0 1-.531-2.754l.841-3.062a3.153 3.153 0 0 1 2.576-2.286l.577-2.16L2.46 9.305A3.152 3.152 0 0 1 .653 8.147 3.167 3.167 0 0 1 .106 5.41l.81-3.062A3.152 3.152 0 0 1 3.963 0h9.944c.314 0 .623.047.916.136.292-.089.6-.136.917-.136h8.442c.978 0 1.9.456 2.497 1.233.596.778.799 1.79.547 2.738l-.81 3.061a3.152 3.152 0 0 1-3.045 2.349h-.206l-6.324 6.983-.318 1.187a3.167 3.167 0 0 1 1.09 3.289l-.812 3.062a3.152 3.152 0 0 1-3.044 2.348z"
            />
            <path
              fill="#FEFEFE"
              d="M30.022 13.312c0-1.19-.631-2.051-1.894-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.601 2.081 1.924 2.081 2.316 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
            />
            <path
              fill="#FEFEFE"
              d="M26.773 26.6c-6.46 0-10.8-4.013-10.8-9.986 0-6.678 5.07-11.714 11.796-11.714 6.531 0 10.92 3.953 10.92 9.837 0 6.652-5.234 11.863-11.916 11.863"
            />
            <path
              fill="#FEFEFE"
              d="M46.328 20.286c-1.448 2.09-3.347 3.164-5.759 3.164-2.291 0-4.01-1.164-4.01-3.253 0-1.015.393-2.12.815-3.402l1.326-4.358c.151-.448.12-1.074-.331-1.074h-1.146l.814-2.955h3.346c1.628 0 3.497-.18 4.311-.358l-2.32 8.118c-.272.984-.574 2-.574 2.626 0 .686.422 1.104 1.116 1.104 2.05 0 3.527-3.88 5.517-11.49h2.532c1.447 0 2.653-.15 3.407-.358l-2.593 9.67c-.24.776-.422 1.432-.422 1.85 0 .567.302.746.724.746.24 0 .543-.06.784-.149l-.754 2.865c-1.025.239-1.96.418-3.075.418-2.412 0-3.739-1.015-3.739-2.925 0-.03 0-.12.03-.239"
            />
            <path
              fill="#FEFEFE"
              d="M52.791 17.22l-.017.006a.293.293 0 0 1 .017-.006m-13.087 2.952c.038.017.087.035.148.053a4.126 4.126 0 0 1-.108-.377 2.106 2.106 0 0 0-.04.324M50.046 26.6c-1.995 0-3.487-.5-4.565-1.275-1.465.842-3.122 1.275-4.926 1.275-4.166 0-7.19-2.691-7.19-6.4 0-1.443.448-2.796.88-4.103l.082-.247.678-2.222a3.124 3.124 0 0 1-.863-3.099l.817-2.957a3.166 3.166 0 0 1 3.057-2.313h3.355c1.452 0 3.098-.166 3.638-.285a3.19 3.19 0 0 1 2.804.732 3.183 3.183 0 0 1 1.629-.447h2.54c1.244 0 2.159-.131 2.564-.243a3.186 3.186 0 0 1 3.079.792 3.127 3.127 0 0 1 .835 3.045l-2.42 9.01a3.126 3.126 0 0 1 .912 3.102l-.755 2.868c-.298 1.127-1.2 2-2.343 2.266-1.043.242-2.283.501-3.808.501"
            />
            <path
              fill="#FEFEFE"
              d="M73.268 11.112c1.46-2.051 3.314-3.062 5.747-3.062 2.34 0 4.043 1.16 4.043 3.3 0 2.438-2.372 7.016-2.372 8.235 0 .535.274.713.7.713.243 0 .486-.059.79-.148l-.79 2.884c-1.064.297-2.159.416-3.254.416-2.37 0-3.708-.773-3.708-2.914 0-1.932 2.219-6.48 2.219-7.848 0-.684-.364-1.07-1.064-1.07-2.067 0-3.526 4.043-5.533 11.416H64.33c1.794-6.63 2.736-8.949 2.736-10.317 0-.713-.364-1.1-1.064-1.1-1.976 0-3.526 3.954-5.502 11.417h-5.838L57.55 12.48c.213-.833.122-1.07-.334-1.07H56.06l.82-2.944h3.831c1.642 0 3.04-.178 3.861-.357l-.912 3.152c1.49-2.11 3.345-3.211 5.777-3.211 1.641 0 3.679.714 3.83 3.062"
            />
            <path
              fill="#FEFEFE"
              d="M70.189 11.25c.034.102.065.208.091.315a3.199 3.199 0 0 1-.03-.294 1.249 1.249 0 0 0-.061-.022zm9.48-.026c.083.247.145.506.184.775.054-.259.086-.483.086-.657v-.035a1.336 1.336 0 0 0-.27-.083zm-9.317.687c.031.186.052.379.06.576l.084-.16a3.075 3.075 0 0 1-.144-.416zM78.22 26.6c-2.691 0-4.328-.836-5.32-1.906a3.163 3.163 0 0 1-2.688 1.489h-5.659a3.172 3.172 0 0 1-1.896-.63 3.167 3.167 0 0 1-1.896.63h-5.78a3.166 3.166 0 0 1-2.506-1.228 3.12 3.12 0 0 1-.543-2.721l2.304-8.518a3.118 3.118 0 0 1-.915-3.142l.812-2.95a3.153 3.153 0 0 1 3.045-2.307h3.793c1.365 0 2.518-.147 3.141-.283a3.177 3.177 0 0 1 2.308.374 9.853 9.853 0 0 1 3.19-.508c1.747 0 3.268.478 4.44 1.325 1.478-.881 3.163-1.325 5.043-1.325 4.216 0 7.16 2.649 7.16 6.442 0 1.991-.886 4.375-1.669 6.479l-.067.182a3.122 3.122 0 0 1 .755 2.97l-.783 2.89a3.145 3.145 0 0 1-2.183 2.2c-1.26.356-2.635.537-4.086.537z"
            />
            <path
              fill="#FEFEFE"
              d="M94.978 13.312c0-1.19-.632-2.051-1.895-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.602 2.081 1.925 2.081 2.315 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
            />
            <path
              fill="#FEFEFE"
              d="M91.729 26.6c-6.46 0-10.8-4.013-10.8-9.986 0-6.678 5.07-11.714 11.795-11.714 6.532 0 10.921 3.953 10.921 9.837 0 6.652-5.234 11.863-11.916 11.863"
            />
            <path
              fill="#E52708"
              d="M4.503 20.035H5.78c.547 0 .79-.21 1.004-1.081l1.186-4.386-2.86-7.332c-.273-.72-.517-1.021-1.156-1.021h-.76l.82-3.065h10.072l-.853 3.065h-.851c-.67 0-.974.51-.73 1.262l1.43 3.785 3.437-3.966c.548-.63.335-1.081-.365-1.081h-1.034l.821-3.065h8.55l-.822 3.065h-.73c-.7 0-1.156.3-1.826 1.021l-6.937 7.572-1.125 4.146c-.243.871-.152 1.081.426 1.081h1.278l-.822 3.065H3.651l.852-3.065z"
            />
            <path
              fill="#E52708"
              d="M30.022 13.312c0-1.19-.631-2.051-1.894-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.601 2.081 1.924 2.081 2.316 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838M46.328 20.286c-1.448 2.09-3.347 3.164-5.759 3.164-2.291 0-4.01-1.164-4.01-3.253 0-1.015.393-2.12.815-3.402l1.326-4.358c.151-.448.12-1.074-.331-1.074h-1.146l.814-2.955h3.346c1.628 0 3.497-.18 4.311-.358l-2.32 8.118c-.272.984-.574 2-.574 2.626 0 .686.422 1.104 1.116 1.104 2.05 0 3.527-3.88 5.517-11.49h2.532c1.447 0 2.653-.15 3.407-.358l-2.593 9.67c-.24.776-.422 1.432-.422 1.85 0 .567.302.746.724.746.24 0 .543-.06.784-.149l-.754 2.865c-1.025.239-1.96.418-3.075.418-2.412 0-3.739-1.015-3.739-2.925 0-.03 0-.12.03-.239M73.268 11.112c1.46-2.051 3.314-3.062 5.747-3.062 2.34 0 4.043 1.16 4.043 3.3 0 2.438-2.372 7.016-2.372 8.235 0 .535.274.713.7.713.243 0 .486-.059.79-.148l-.79 2.884c-1.064.297-2.159.416-3.254.416-2.37 0-3.708-.773-3.708-2.914 0-1.932 2.219-6.48 2.219-7.848 0-.684-.364-1.07-1.064-1.07-2.067 0-3.526 4.043-5.533 11.416H64.33c1.794-6.63 2.736-8.949 2.736-10.317 0-.713-.364-1.1-1.064-1.1-1.976 0-3.526 3.954-5.502 11.417h-5.838L57.55 12.48c.213-.833.122-1.07-.334-1.07H56.06l.82-2.944h3.831c1.642 0 3.04-.178 3.861-.357l-.912 3.152c1.49-2.11 3.345-3.211 5.777-3.211 1.641 0 3.679.714 3.83 3.062M94.978 13.312c0-1.19-.632-2.051-1.895-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.602 2.081 1.925 2.081 2.315 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
            />
          </g>
        </svg>
      </a>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="28"
      viewBox="0 0 104 28"
    >
      <defs>
        <path id="a" d="M22.764 21.678H.011V.037h22.753v21.641z" />
        <path id="b" d="M.258 21.678h34.573V.038H.258z" />
        <path id="c" d="M23.08 21.678H.326V.037H23.08v21.641z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#060504"
          d="M4.47 21.077h1.27c.543 0 .785-.208.997-1.07l1.178-4.336-2.841-7.248c-.272-.713-.514-1.01-1.149-1.01H3.17l.816-3.03h10.006l-.847 3.03h-.846c-.665 0-.968.505-.726 1.247l1.421 3.743 3.416-3.92c.544-.625.332-1.07-.363-1.07h-1.028l.816-3.03h8.495l-.816 3.03h-.726c-.695 0-1.149.297-1.814 1.01l-6.892 7.485-1.118 4.1c-.242.861-.152 1.07.423 1.07h1.27l-.817 3.03H3.623l.846-3.03z"
        />
        <path
          fill="#060504"
          d="M8.114 7.51l.211.537c.006-.182.024-.361.053-.537h-.264zM10.96 14.8c.027.09.049.182.067.274.017-.06.035-.119.056-.177a3.032 3.032 0 0 1-.123-.097zm73.347 10.552a3.157 3.157 0 0 1-2.04 1.761c-1.265.356-2.645.536-4.102.536-2.702 0-4.346-.834-5.343-1.9a3.18 3.18 0 0 1-2.698 1.484h-5.682a3.2 3.2 0 0 1-1.905-.627 3.194 3.194 0 0 1-1.904.627H54.83a3.21 3.21 0 0 1-.831-.11c-.036.01-.071.019-.107.027-1.043.242-2.283.499-3.807.499-1.995 0-3.488-.497-4.565-1.268-1.465.837-3.123 1.268-4.927 1.268-2.612 0-4.775-1.052-6.025-2.747-2.069 1.722-4.756 2.747-7.729 2.747-4.284 0-7.638-1.755-9.414-4.67l-.52 1.93a3.163 3.163 0 0 1-3.064 2.324H3.623c-.99 0-1.922-.456-2.521-1.232a3.093 3.093 0 0 1-.535-2.726l.847-3.03a3.155 3.155 0 0 1 2.591-2.262l.58-2.137-2.11-5.383A3.177 3.177 0 0 1 .658 9.319c-.6-.77-.803-1.77-.55-2.71l.815-3.03a3.163 3.163 0 0 1 3.064-2.323H13.99c.316 0 .627.047.922.135.294-.088.604-.135.923-.135h8.493c.985 0 1.913.451 2.513 1.22.6.77.804 1.771.55 2.71l-.225.84c.22-.011.444-.017.669-.017 2.954 0 5.47.804 7.342 2.225a3.173 3.173 0 0 1 2.875-1.81h3.355c1.453 0 3.098-.164 3.638-.282a3.202 3.202 0 0 1 2.804.728 3.196 3.196 0 0 1 1.63-.445h2.538c1.245 0 2.16-.13 2.565-.242a3.207 3.207 0 0 1 2.326.244l.127-.002h3.809c1.37 0 2.528-.146 3.154-.283a3.209 3.209 0 0 1 2.318.373 9.956 9.956 0 0 1 3.203-.506c1.753 0 3.282.477 4.458 1.322 1.484-.88 3.176-1.322 5.064-1.322 2.588 0 4.698.987 5.948 2.618 2.029-1.65 4.667-2.618 7.624-2.618 6.543 0 10.939 3.943 10.939 9.81 0 6.634-5.243 11.831-11.936 11.831-2.947 0-5.454-.83-7.31-2.297z"
          opacity=".2"
        />
        <path
          fill="#060504"
          d="M30.103 14.394c0-1.189-.635-2.05-1.904-2.05-2.328 0-3.507 5.02-3.507 6.891 0 1.248.605 2.08 1.935 2.08 2.327 0 3.476-5.05 3.476-6.921M19.191 17.69c0-4.872 3.567-8.555 8.645-8.555 4.534 0 7.769 2.317 7.769 6.684 0 4.901-3.719 8.703-8.767 8.703-4.503 0-7.647-2.466-7.647-6.832M46.366 21.374c-1.451 2.08-3.356 3.15-5.774 3.15-2.297 0-4.02-1.16-4.02-3.239 0-1.01.393-2.109.816-3.386l1.33-4.337c.151-.446.12-1.07-.332-1.07h-1.149l.816-2.94h3.355c1.633 0 3.507-.179 4.323-.357l-2.327 8.08c-.273.98-.575 1.99-.575 2.614 0 .683.423 1.1 1.118 1.1 2.056 0 3.537-3.863 5.532-11.438h2.54c1.45 0 2.66-.148 3.415-.356l-2.6 9.625c-.241.772-.423 1.425-.423 1.841 0 .565.303.743.726.743.242 0 .544-.06.786-.149l-.756 2.852c-1.027.238-1.965.416-3.083.416-2.418 0-3.749-1.01-3.749-2.911 0-.03 0-.119.03-.238M73.329 12.195c1.45-2.05 3.294-3.06 5.713-3.06 2.328 0 4.02 1.16 4.02 3.298 0 2.436-2.358 7.01-2.358 8.228 0 .535.273.713.696.713.241 0 .484-.059.785-.148l-.785 2.881c-1.058.297-2.146.416-3.235.416-2.358 0-3.687-.772-3.687-2.911 0-1.931 2.206-6.476 2.206-7.842 0-.683-.362-1.07-1.058-1.07-2.055 0-3.506 4.04-5.501 11.407H64.44c1.784-6.624 2.72-8.941 2.72-10.308 0-.712-.361-1.099-1.057-1.099-1.965 0-3.506 3.95-5.47 11.407h-5.805L57.7 13.562c.211-.832.12-1.07-.333-1.07H56.22l.815-2.94h3.81c1.632 0 3.022-.179 3.838-.357l-.906 3.149c1.48-2.11 3.325-3.208 5.743-3.208 1.632 0 3.658.713 3.809 3.06M94.881 14.394c0-1.189-.634-2.05-1.904-2.05-2.328 0-3.506 5.02-3.506 6.891 0 1.248.604 2.08 1.934 2.08 2.328 0 3.476-5.05 3.476-6.921M83.97 17.69c0-4.872 3.567-8.555 8.645-8.555 4.535 0 7.77 2.317 7.77 6.684 0 4.901-3.719 8.703-8.767 8.703-4.504 0-7.648-2.466-7.648-6.832"
        />
        <path
          fill="#FEFEFE"
          d="M4.503 20.035H5.78c.547 0 .79-.21 1.004-1.081l1.186-4.386-2.86-7.332c-.273-.72-.517-1.021-1.156-1.021h-.76l.82-3.065h10.072l-.853 3.065h-.851c-.67 0-.974.51-.73 1.262l1.43 3.785 3.437-3.966c.548-.63.335-1.081-.365-1.081h-1.034l.821-3.065h8.55l-.822 3.065h-.73c-.7 0-1.156.3-1.826 1.021l-6.937 7.572-1.125 4.146c-.243.871-.152 1.081.426 1.081h1.278l-.822 3.065H3.651l.852-3.065z"
        />
        <path
          fill="#FEFEFE"
          d="M8.065 6.319l.21.543a4.31 4.31 0 0 1 .052-.543h-.262zm2.828 7.368c.026.091.049.183.067.277.016-.06.035-.12.055-.18a3.021 3.021 0 0 1-.122-.097zm2.863 12.563H3.6c-.983 0-1.91-.46-2.506-1.245a3.167 3.167 0 0 1-.531-2.754l.841-3.062a3.153 3.153 0 0 1 2.576-2.286l.577-2.16L2.46 9.305A3.152 3.152 0 0 1 .653 8.147 3.167 3.167 0 0 1 .106 5.41l.81-3.062A3.152 3.152 0 0 1 3.963 0h9.944c.314 0 .623.047.916.136.292-.089.6-.136.917-.136h8.442c.978 0 1.9.456 2.497 1.233.596.778.799 1.79.547 2.738l-.81 3.061a3.152 3.152 0 0 1-3.045 2.349h-.206l-6.324 6.983-.318 1.187a3.167 3.167 0 0 1 1.09 3.289l-.812 3.062a3.152 3.152 0 0 1-3.044 2.348z"
        />
        <path
          fill="#FEFEFE"
          d="M30.022 13.312c0-1.19-.631-2.051-1.894-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.601 2.081 1.924 2.081 2.316 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
        />
        <path
          fill="#FEFEFE"
          d="M26.773 26.6c-6.46 0-10.8-4.013-10.8-9.986 0-6.678 5.07-11.714 11.796-11.714 6.531 0 10.92 3.953 10.92 9.837 0 6.652-5.234 11.863-11.916 11.863"
        />
        <path
          fill="#FEFEFE"
          d="M46.328 20.286c-1.448 2.09-3.347 3.164-5.759 3.164-2.291 0-4.01-1.164-4.01-3.253 0-1.015.393-2.12.815-3.402l1.326-4.358c.151-.448.12-1.074-.331-1.074h-1.146l.814-2.955h3.346c1.628 0 3.497-.18 4.311-.358l-2.32 8.118c-.272.984-.574 2-.574 2.626 0 .686.422 1.104 1.116 1.104 2.05 0 3.527-3.88 5.517-11.49h2.532c1.447 0 2.653-.15 3.407-.358l-2.593 9.67c-.24.776-.422 1.432-.422 1.85 0 .567.302.746.724.746.24 0 .543-.06.784-.149l-.754 2.865c-1.025.239-1.96.418-3.075.418-2.412 0-3.739-1.015-3.739-2.925 0-.03 0-.12.03-.239"
        />
        <path
          fill="#FEFEFE"
          d="M52.791 17.22l-.017.006a.293.293 0 0 1 .017-.006m-13.087 2.952c.038.017.087.035.148.053a4.126 4.126 0 0 1-.108-.377 2.106 2.106 0 0 0-.04.324M50.046 26.6c-1.995 0-3.487-.5-4.565-1.275-1.465.842-3.122 1.275-4.926 1.275-4.166 0-7.19-2.691-7.19-6.4 0-1.443.448-2.796.88-4.103l.082-.247.678-2.222a3.124 3.124 0 0 1-.863-3.099l.817-2.957a3.166 3.166 0 0 1 3.057-2.313h3.355c1.452 0 3.098-.166 3.638-.285a3.19 3.19 0 0 1 2.804.732 3.183 3.183 0 0 1 1.629-.447h2.54c1.244 0 2.159-.131 2.564-.243a3.186 3.186 0 0 1 3.079.792 3.127 3.127 0 0 1 .835 3.045l-2.42 9.01a3.126 3.126 0 0 1 .912 3.102l-.755 2.868c-.298 1.127-1.2 2-2.343 2.266-1.043.242-2.283.501-3.808.501"
        />
        <path
          fill="#FEFEFE"
          d="M73.268 11.112c1.46-2.051 3.314-3.062 5.747-3.062 2.34 0 4.043 1.16 4.043 3.3 0 2.438-2.372 7.016-2.372 8.235 0 .535.274.713.7.713.243 0 .486-.059.79-.148l-.79 2.884c-1.064.297-2.159.416-3.254.416-2.37 0-3.708-.773-3.708-2.914 0-1.932 2.219-6.48 2.219-7.848 0-.684-.364-1.07-1.064-1.07-2.067 0-3.526 4.043-5.533 11.416H64.33c1.794-6.63 2.736-8.949 2.736-10.317 0-.713-.364-1.1-1.064-1.1-1.976 0-3.526 3.954-5.502 11.417h-5.838L57.55 12.48c.213-.833.122-1.07-.334-1.07H56.06l.82-2.944h3.831c1.642 0 3.04-.178 3.861-.357l-.912 3.152c1.49-2.11 3.345-3.211 5.777-3.211 1.641 0 3.679.714 3.83 3.062"
        />
        <path
          fill="#FEFEFE"
          d="M70.189 11.25c.034.102.065.208.091.315a3.199 3.199 0 0 1-.03-.294 1.249 1.249 0 0 0-.061-.022zm9.48-.026c.083.247.145.506.184.775.054-.259.086-.483.086-.657v-.035a1.336 1.336 0 0 0-.27-.083zm-9.317.687c.031.186.052.379.06.576l.084-.16a3.075 3.075 0 0 1-.144-.416zM78.22 26.6c-2.691 0-4.328-.836-5.32-1.906a3.163 3.163 0 0 1-2.688 1.489h-5.659a3.172 3.172 0 0 1-1.896-.63 3.167 3.167 0 0 1-1.896.63h-5.78a3.166 3.166 0 0 1-2.506-1.228 3.12 3.12 0 0 1-.543-2.721l2.304-8.518a3.118 3.118 0 0 1-.915-3.142l.812-2.95a3.153 3.153 0 0 1 3.045-2.307h3.793c1.365 0 2.518-.147 3.141-.283a3.177 3.177 0 0 1 2.308.374 9.853 9.853 0 0 1 3.19-.508c1.747 0 3.268.478 4.44 1.325 1.478-.881 3.163-1.325 5.043-1.325 4.216 0 7.16 2.649 7.16 6.442 0 1.991-.886 4.375-1.669 6.479l-.067.182a3.122 3.122 0 0 1 .755 2.97l-.783 2.89a3.145 3.145 0 0 1-2.183 2.2c-1.26.356-2.635.537-4.086.537z"
        />
        <path
          fill="#FEFEFE"
          d="M94.978 13.312c0-1.19-.632-2.051-1.895-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.602 2.081 1.925 2.081 2.315 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
        />
        <path
          fill="#FEFEFE"
          d="M91.729 26.6c-6.46 0-10.8-4.013-10.8-9.986 0-6.678 5.07-11.714 11.795-11.714 6.532 0 10.921 3.953 10.921 9.837 0 6.652-5.234 11.863-11.916 11.863"
        />
        <path
          fill="#E52708"
          d="M4.503 20.035H5.78c.547 0 .79-.21 1.004-1.081l1.186-4.386-2.86-7.332c-.273-.72-.517-1.021-1.156-1.021h-.76l.82-3.065h10.072l-.853 3.065h-.851c-.67 0-.974.51-.73 1.262l1.43 3.785 3.437-3.966c.548-.63.335-1.081-.365-1.081h-1.034l.821-3.065h8.55l-.822 3.065h-.73c-.7 0-1.156.3-1.826 1.021l-6.937 7.572-1.125 4.146c-.243.871-.152 1.081.426 1.081h1.278l-.822 3.065H3.651l.852-3.065z"
        />
        <path
          fill="#E52708"
          d="M30.022 13.312c0-1.19-.631-2.051-1.894-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.601 2.081 1.924 2.081 2.316 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838M46.328 20.286c-1.448 2.09-3.347 3.164-5.759 3.164-2.291 0-4.01-1.164-4.01-3.253 0-1.015.393-2.12.815-3.402l1.326-4.358c.151-.448.12-1.074-.331-1.074h-1.146l.814-2.955h3.346c1.628 0 3.497-.18 4.311-.358l-2.32 8.118c-.272.984-.574 2-.574 2.626 0 .686.422 1.104 1.116 1.104 2.05 0 3.527-3.88 5.517-11.49h2.532c1.447 0 2.653-.15 3.407-.358l-2.593 9.67c-.24.776-.422 1.432-.422 1.85 0 .567.302.746.724.746.24 0 .543-.06.784-.149l-.754 2.865c-1.025.239-1.96.418-3.075.418-2.412 0-3.739-1.015-3.739-2.925 0-.03 0-.12.03-.239M73.268 11.112c1.46-2.051 3.314-3.062 5.747-3.062 2.34 0 4.043 1.16 4.043 3.3 0 2.438-2.372 7.016-2.372 8.235 0 .535.274.713.7.713.243 0 .486-.059.79-.148l-.79 2.884c-1.064.297-2.159.416-3.254.416-2.37 0-3.708-.773-3.708-2.914 0-1.932 2.219-6.48 2.219-7.848 0-.684-.364-1.07-1.064-1.07-2.067 0-3.526 4.043-5.533 11.416H64.33c1.794-6.63 2.736-8.949 2.736-10.317 0-.713-.364-1.1-1.064-1.1-1.976 0-3.526 3.954-5.502 11.417h-5.838L57.55 12.48c.213-.833.122-1.07-.334-1.07H56.06l.82-2.944h3.831c1.642 0 3.04-.178 3.861-.357l-.912 3.152c1.49-2.11 3.345-3.211 5.777-3.211 1.641 0 3.679.714 3.83 3.062M94.978 13.312c0-1.19-.632-2.051-1.895-2.051-2.315 0-3.488 5.024-3.488 6.897 0 1.248.602 2.081 1.925 2.081 2.315 0 3.458-5.054 3.458-6.927m-10.855 3.3c0-4.876 3.548-8.562 8.6-8.562 4.51 0 7.728 2.319 7.728 6.69 0 4.905-3.699 8.71-8.72 8.71-4.48 0-7.608-2.468-7.608-6.838"
        />
      </g>
    </svg>
  );
};

export default LogoYoumo;
