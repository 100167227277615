export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

export function log(type = 'log', ...msg) {
  // Do not log in production.
  if (process.env.NODE_ENV === 'production') return () => {};
  // Default to log function if we try to send something else
  if (typeof console[type] !== 'function') type = 'log';
  // if we pass a message log directly (will not give correct file and linenumer in console)
  if (msg && msg.length > 0) {
    return console[type](...msg);
  }
  // return console function to be run by caller.
  return console[type];
}

export function callIfFunction(fn, ...args) {
  if (typeof fn === 'function') {
    return fn(...args);
  }
  return;
}

/**
 * Convert object to string and compare them.
 * Not recommended but can be useful in certain situations.
 */
export function jsonStringifyIsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Check if param is an empty object.
 * `isEmptyObject({}) // true`
 */
export function isEmptyObject(v) {
  for (var key in v) {
    if (Object.prototype.hasOwnProperty.call(v, key)) return false;
  }
  return true;
}

/**
 * Check if param is an empty string.
 * `isEmptyString("") // true`
 */
export function isEmptyString(v) {
  if (typeof v === 'string' && v === '') return true;
  return false;
}

/**
 * Check if param is an empty array.
 * `isEmptyArray([]) // true`
 */
export function isEmptyArray(v) {
  if (Array.isArray(v) && v.length === 0) return true;
  return false;
}

/**
 * Check if param is falsy or empty array, object or string.
 ```js
 isFalseOrEmpty("") == true
 isFalseOrEmpty([]) == true
 isFalseOrEmpty({}) == true
 isFalseOrEmpty(null) == true
 isFalseOrEmpty(undefined) == true
 isFalseOrEmpty(false) == true
 isFalseOrEmpty(0) == false
 ```
 */
export function isFalseOrEmpty(v) {
  if (v === 0) return false;
  if (!v) return true;
  return isEmptyString(v) || isEmptyArray(v) || isEmptyObject(v);
}

/**
 * Make first char of string uppercase.
 */
export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function preloadedState() {
  if (typeof window === 'undefined') {
    return {};
  }
  if (window.__PRELOADED_STATE__) {
    return window.__PRELOADED_STATE__;
  }
  return {};
}

export function useSweLang(lang) {
  if (lang == 'sv' || lang == 'sv-SE-x-ll' || lang == 'swl') {
    return null;
  }
  return 'sv';
}

export const getCurrentRegion = () => {
  const { Regionalization } = preloadedState();

  if (
    Regionalization &&
    Regionalization.Regions &&
    Regionalization.Regions.Options &&
    Regionalization.Regions.Options.length > 0
  ) {
    return Regionalization.Regions.Options.find((opt) => opt.Selected === true);
  }

  return null;
};

/**
 * Strips html tags from a string.
 */
export const stripHtml = (text) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.body.textContent || '';
}
