import React from 'react';
import T from 'prop-types';
import ErrorBoundary from './error-boundary';
import FallbackComponent from './fallback-component';

const PageTypeRenderer = (props) => {
  //TODO: throw bara i dev
  if (!props.PageTypes[props.PageTypeName])
    throw new Error(
      `There is no pagetype with name ${props.PageTypeName} in React app. Check exported pages.`
    );

  const RenderPageType = props.PageTypes[props.PageTypeName];

  const renderFallback = (props) => (
    <FallbackComponent
      title="Något gick fel i pagetyperenderer"
      message="Jahopp..."
      block={props.PageTypes[props.PageTypeName]}
    />
  );

  return (
    <ErrorBoundary renderFallback={renderFallback}>
      <RenderPageType {...props} />{' '}
    </ErrorBoundary>
  );
};

PageTypeRenderer.propTypes = {
  PageTypeName: T.string,
  PageTypes: T.object,
};

export default PageTypeRenderer;
