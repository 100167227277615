import React from 'react';
import classNames from 'classnames';
import ReadingAidButton from './reading-aid-button';

const ReadingAidPanel = (props) => {
  const { ReadingAid, isVisible } = props;
  const readingAidClasses = classNames({
    'c-reading-aid': true,
    'c-reading-aid--is-opened': isVisible,
    ...props.modifiers,
  });

  return (
    <div
      className={readingAidClasses}
      id="reading-aid"
      aria-hidden={!isVisible}
    >
      <div className="row">
        <div className="columns">
          <div className="c-reading-aid__inner">
            <div
              dangerouslySetInnerHTML={{ __html: ReadingAid.ReadingAidText }}
            />
            <ReadingAidButton {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingAidPanel;
