import React from 'react';

const removeNotInDateRange = ({ StartDate, StopDate }) => {
  if (!StartDate && !StopDate) return true;
  const date = new Date().toISOString();
  if (StartDate && !StopDate) {
    return StartDate < date;
  }
  if (StopDate && !StartDate) {
    return StopDate > date;
  }
  return StartDate < date && StopDate > date;
};

export function getItemsWithRelevantDate(items) {
  if (!items) return [];
  return items.filter(removeNotInDateRange);
}

export default (WrappedComponent) => {
  return (props) => {
    const { Messages, PageTypeName } = props;
    const messages =
      Messages &&
      PageTypeName !== 'LanguagePage' &&
      getItemsWithRelevantDate(Messages);
    return <WrappedComponent {...props} Messages={messages} />;
  };
};
