import React from 'react';
import classNames from 'classnames';
import { node, object, oneOf, string } from 'prop-types';

const Alert = (props) => {
  const classes = classNames(
    `c-alert`,
    `c-alert--${props.type}`,
    props.Modifiers
  );

  const alertNoTitle = !props.Title ? 'c-alert__no-title' : '';

  return (
    <div role="alert" className={classes} data-cy="ui-alert">
      <div className={`c-alert__inner ${alertNoTitle}`}>
        <div className={`c-alert__icon-container`}>
          <i className="c-alert__icon" />
          {props.Title && (
            <h2 className='c-alert__title'>{props.Title}</h2>
          )}
          {props.AlertDescription && (
            <span className='show-for-sr'>{props.AlertDescription}</span>
          )}
        </div>
        {props.children}
      </div>
    </div>
  );
};

Alert.propTypes = {
  /** What kind of alert is it */
  type: oneOf(['success', 'info', 'warning', 'check', 'error', 'error-boundary']),
  /** Body of content */
  children: node,
  /** Valid object for use with classnames  */
  Modifiers: object,
  /** Title for the Alert, if needed */
  Title: string,
  /** Description of the Alert type, used by screenreaders */
  AlertDescription: string,
};

export default Alert;
