import Alert from '../../Alert';
import React from 'react';
const FallbackComponent = ({ title, message }) => {
  return (
    <Alert type="error-boundary">
      <div>
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    </Alert>
  );
};
export default FallbackComponent;
