import React, { useState, useEffect } from 'react';
import proptypes from 'prop-types';

const HoverModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  /** How to use
   * Add position relative to the main element
   * where the hoverbox should be attached to.
   * Include the modal and specify element and triggerelement
   * Ex: @include HoverModal('hover-modal', 'button');
   **/

  useEffect(() => {
    const element = document.getElementById(props.ElementId);

    element.onclick = () => {
      setShowModal(false);
    };
    element.onmouseover = () => {
      setShowModal(!props.isHidden);
    };
    element.onmouseout = () => {
      setShowModal(false);
    };
    element.onblur = () => {
      setShowModal(false);
    };
    element.onfocus = () => {
      setShowModal(!props.isHidden);
    };
  }, []);

  const { Heading, Description, Id } = props;

  if (!showModal) return null;
  return (
    <div className="hover-modal" id={Id} aria-hidden="true">
      {Heading && <div dangerouslySetInnerHTML={{ __html: Heading }} />}
      {Description && <div dangerouslySetInnerHTML={{ __html: Description }} />}
    </div>
  );
};

HoverModal.propTypes = {
  Heading: proptypes.string,
  Description: proptypes.string,
  Id: proptypes.string,
};

export default HoverModal;
