import React from 'react';
import classNames from 'classnames';

const ExpandBtn = (props) => {
  if (props.IsLeafNode) return null;

  const expandBtnClasses = classNames({
    'c-expand-button': true,
    [`${props.ComponentClassName}-expand`]: true,
    'c-expand-button--is-open': props.isOpen,
  });

  const chevronBtnClasses = classNames({
    [`${props.chevronMobile}`]: true,
    [`${props.ComponentClassName}-expand`]: true,
    [`${props.chevronMobile}--is-open`]: props.isOpen,
  });

  const btnClasses = props.chevronMobile ? chevronBtnClasses : expandBtnClasses;

  return (
    <button
      onClick={(e) => props.handleClick(e)}
      className={btnClasses}
      aria-controls={props.Id}
      aria-expanded={props.isOpen}
      type="button"
      data-cy="ui-mobilenav-expand-button"
    >
      <span className="show-for-sr">{`${props.ExpandBtnText} ${props.Text}`}</span>
    </button>
  );
};

ExpandBtn.defaultProps = {
  ExpandBtnText: '',
};

export default ExpandBtn;
