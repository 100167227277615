import React, { useEffect } from 'react';
import { isMediumViewport } from '../Hooks/useMedia';
import classNames from 'classnames';
import withAutocomplete from '../Containers/withAutocomplete';
import PropTypes from 'prop-types';

/**
 * Repo: PWT-PlattformUI
 *
 * Containers: withAutocomplete
 *
 * Sites: 1177, RGS
 *
 * @description Search field used in the global page search.
 *
 */
const SearchField = ({
  hasNoAutocomplete,
  handleKeyDown,
  handleChange,
  handleBlur,
  Suggestions = {},
  id,
  searchUrl,
  className,
  defaultValue,
  placeholder,
  hasQuickSearch,
}) => {
  let selectedTerm = null;

  const isMobileViewport = isMediumViewport();

  useEffect(() => {
    document.body.classList.remove('no-scroll');
    if (Suggestions.Hits?.SearchHits?.length > 5 && isMobileViewport) {
      document.body.classList.add('no-scroll');
    }
  }, [Suggestions.Hits]);

  if (hasNoAutocomplete) {
    return (
      <div className={`${className}__field-container`}>
        <input
          type="text"
          defaultValue={defaultValue}
          id={id}
          name="q"
          placeholder={placeholder}
          className={`${className}__field__query`}
        />
      </div>
    );
  }

  const suggestionsArray = hasQuickSearch
    ? Suggestions.Hits.AutoComplete
    : Suggestions.Hits;

  const suggest =
    Array.isArray(suggestionsArray) &&
    suggestionsArray.map((term, index) => {
      const itemClass = classNames({
        'c-search__autocomplete__item': !hasQuickSearch,
        'c-search__autocomplete__item--is-selected':
          !hasQuickSearch && Suggestions.index === index,
        'c-search__quicksearch__autocomplete__item': hasQuickSearch,
        'c-search__quicksearch__autocomplete__item--is-selected':
          hasQuickSearch && Suggestions.index === index,
      });

      if (Suggestions.index === index) selectedTerm = term;

      return (
        <li role="option" className={itemClass} key={index}>
          <a href={encodeURI(`${searchUrl}?q=${term}`)}>{term}</a>
        </li>
      );
    });

  const searchHits =
    Array.isArray(Suggestions.Hits.SearchHits) &&
    Suggestions.Hits.SearchHits.map((term, index) => {
      const quickSearchClasses = classNames({
        'c-search__quicksearch__search-hits__information-type': true,
        'c-search__quicksearch__search-hits__information-type--1001':
          term.InformationType?.Key === '1001',
        'c-search__quicksearch__search-hits__information-type--1002':
          term.InformationType?.Key === '1002',
        'c-search__quicksearch__search-hits__information-type--1003':
          term.InformationType?.Key === '1003',
        'c-search__quicksearch__search-hits__information-type--1004':
          term.InformationType?.Key === '1004',
      });

      return (
        <li key={index}>
          <a
            className="c-search__quicksearch__search-hits__item"
            href={term.Url}
          >
            <span className="c-search__quicksearch__search-hits__heading">
              {term.Heading}
            </span>
            {term.InformationType?.Key !== 'article' && (  
              <span className={quickSearchClasses}>
                {term.InformationType.Value}
              </span>  
            )}    
          </a>
          {term.SelectionCode && term.SelectionCode !== 'none' && 
            (term.PrimaryCareUrl || term.SpecializedCareUrl) && (
            <span className="c-search__quicksearch__search-hits__selection-code">
              {term.PrimaryCareUrl && term.SpecializedCareUrl ? (
                <>
                  <a
                    className="c-search__quicksearch__search-hits__selection-code-url"
                    href={term.PrimaryCareUrl}
                  >
                    Primärvård
                  </a>{' '}
                  <a
                    className="c-search__quicksearch__search-hits__selection-code-url"
                    href={term.SpecializedCareUrl}
                  >
                    Specialiserad vård
                  </a>
                </>
              ) : term.PrimaryCareUrl ? (
                <a href={term.PrimaryCareUrl}>Primärvård</a>
              ) : (
                <a href={term.SpecializedCareUrl}>Specialiserad vård</a>
              )}
            </span>        
          )}
        </li>
      );
    });

  return (
    <div
      className={`${className}__field-container`}
      data-cy="global-searchfield"
    >
      <input
        value={Suggestions.value}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleBlur(e)}
        type="text"
        id={id}
        name="q"
        placeholder={placeholder}
        className={`${className}__field__query`}
        data-cy="ui-searchform-input"
      />
      {hasQuickSearch &&
        Suggestions.isVisible &&
        (Suggestions.Hits.AutoComplete?.length > 0 ||
          Suggestions.Hits.SearchHits?.length > 0) && (
          <div
            className="c-search__quicksearch"
            aria-expanded={Suggestions.isVisible}
          >
            {Suggestions.Hits.AutoComplete?.length > 0 && (
              <ul className="c-search__quicksearch__autocomplete">
                <span className="c-search__quicksearch__autocomplete__label">
                  {Suggestions.Hits.AutoCompleteLabel}
                </span>
                {suggest}
              </ul>
            )}
            {Suggestions.Hits.SearchHits?.length > 0 && (
              <ul className="c-search__quicksearch__search-hits">
                <h3 className="c-search__quicksearch__search-hits__label">
                  {Suggestions.Hits.SearchHitsHeading}
                </h3>
                {searchHits}
              </ul>
            )}
            <a
              href={encodeURI(`${searchUrl}?q=${Suggestions.value}`)}
              className="c-search__quicksearch__search-hits__show-results"
            >
              {Suggestions.Hits.ButtonText}
            </a>
          </div>
        )}
      {!hasQuickSearch && Suggestions.isVisible && Suggestions.Hits.length > 0 && (
        <ul
          role="listbox"
          aria-expanded={Suggestions.isVisible}
          id={`${id}_listbox`}
          className="c-search__autocomplete"
        >
          {suggest}
        </ul>
      )}

      <div
        role="status"
        aria-relevant="additions"
        className="show-for-sr"
        aria-live="assertive"
      >
        {!Suggestions.isLoading &&
          suggestionsArray &&
          suggestionsArray.length > 0 && (
            <div>
              Det finns {suggestionsArray && suggestionsArray.length}{' '}
              sökordsförslag. Använd uppåt och nedåtpil för att navigera i
              resultatet.
              {selectedTerm}
            </div>
          )}
      </div>
    </div>
  );
};

SearchField.propTypes = {
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  Suggestions: PropTypes.shape({
    Hits: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    index: PropTypes.number,
    value: PropTypes.string,
    isVisible: PropTypes.bool,
  }),
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  searchUrl: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hasNoAutocomplete: PropTypes.bool,
  hasQuickSearch: PropTypes.bool,
};

export const SearchFieldWithoutAutocomplete = (props) => (
  <SearchField {...props} hasNoAutocomplete />
);

export default withAutocomplete(SearchField);
