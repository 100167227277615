import React from 'react';
import T from 'prop-types';
import ErrorBoundary from './error-boundary';
import FallbackComponent from './fallback-component';

const BlockTypeRenderer = (props) => {
  if (!props.Blocks[props.BlockTypeName]) {
    throw new Error(
      `There is no blocktype with name ${props.BlockTypeName} in React app. Check exported blocks.`
    );
  }
  const RenderBlockType = props.Blocks[props.BlockTypeName];

  return <RenderBlockType {...props} />;
};

BlockTypeRenderer.propTypes = {
  BlockTypeName: T.string,
  Blocks: T.object,
};

const BlockTypeRendererWithBoundary = (props) => {
  const renderFallback = () => (
    <FallbackComponent title="Något gick fel i blocket." message="Hoppsan..." />
  );
  return (
    <ErrorBoundary renderFallback={renderFallback}>
      <BlockTypeRenderer {...props} />
    </ErrorBoundary>
  );
};

export default BlockTypeRendererWithBoundary;
