import React from 'react';
import CollapsiblePanel from '../CollapsiblePanel';
import Linklist from '../Linklist';
import { useSweLang } from '../Helpers/helpers';
import classNames from 'classnames';

const Footer = (props) => {
  if (!props.SiteFooter) return false;

  const { SiteFooter, PageTypeName, FooterLogo, ShowLogo, Modifiers } = props;

  let numOfLists = 0;
  if (
    SiteFooter.LinkListFirst ||
    SiteFooter.LinkListSecond ||
    SiteFooter.LinkListThird
  ) {
    const linkLists = new Array(
      SiteFooter.LinkListFirst.Links,
      SiteFooter.LinkListSecond.Links,
      SiteFooter.LinkListThird.Links
    );

    linkLists.forEach((list) => {
      if (list !== undefined) {
        numOfLists++;
      }
    });
  }
  const columnClasses = classNames({
    columns: true,
    'medium-12': true,
    'large-6': numOfLists < 3,
    'large-4': numOfLists === 3,
  });

  return (
    <footer className="c-footer" lang={useSweLang(props.Language)}>
      {PageTypeName !== 'LanguagePage' && (
        <div className="c-footer__content">
          <div className="row c-footer__content__inner">
            <div className="medium-8 medium-offset-2 large-5 large-push-0 columns">
              <h1
                className="c-footer__heading"
                aria-label={`${SiteFooter.Heading} ${SiteFooter.SubHeading}`.trim()}
              >
                {SiteFooter.Heading}
              </h1>
              {SiteFooter.SubHeading && (
                <h2 aria-hidden={true} className="c-footer__subheading">
                  – {SiteFooter.SubHeading}
                </h2>
              )}
            </div>
          </div>
          <div className="row">
            <div className="medium-8 medium-offset-2 large-5 large-push-0 columns">
              <div
                className="c-footer__content__editor"
                dangerouslySetInnerHTML={{ __html: SiteFooter.MainContent }}
              />
            </div>
            <div className="large-push-1 large-6 columns show-for-large end">
              <div className="row">
                {SiteFooter.LinkListFirst.Links !== undefined && (
                  <div className={columnClasses}>
                    <Linklist
                      Modifiers={Modifiers}
                      {...SiteFooter.LinkListFirst}
                      Heading={null}
                    />
                  </div>
                )}
                {SiteFooter.LinkListSecond.Links !== undefined && (
                  <div className={columnClasses}>
                    <Linklist
                      Modifiers={Modifiers}
                      {...SiteFooter.LinkListSecond}
                      Heading={null}
                    />
                  </div>
                )}
                {SiteFooter.LinkListThird.Links !== undefined && (
                  <div className={columnClasses}>
                    <Linklist
                      Modifiers={Modifiers}
                      {...SiteFooter.LinkListThird}
                      Heading={null}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {PageTypeName !== 'LanguagePage' && (
        <div className="c-footer__collapsible-lists hide-for-large">
          {SiteFooter.LinkListFirst && SiteFooter.LinkListFirst.Links && (
            <CollapsiblePanel
              Id="panel1"
              className="c-collapsible-panel"
              Heading={
                SiteFooter.LinkListFirst
                  ? SiteFooter.LinkListFirst.Heading
                  : null
              }
              Language={SiteFooter.LinkListFirst.HeadingLanguage}
              ExpandBtnText={
                props.Content.Translations &&
                props.Content.Translations.expandbuttontext
              }
            >
              <Linklist
                Modifiers={{
                  'c-linklist--collapsible': true,
                  'c-linklist--no-icons': true,
                }}
                {...SiteFooter.LinkListFirst}
                Heading={null}
              />
            </CollapsiblePanel>
          )}

          {SiteFooter.LinkListSecond && SiteFooter.LinkListSecond.Links && (
            <CollapsiblePanel
              Id="panel2"
              className="c-collapsible-panel"
              Heading={
                SiteFooter.LinkListSecond
                  ? SiteFooter.LinkListSecond.Heading
                  : null
              }
              Language={SiteFooter.LinkListSecond.HeadingLanguage}
              ExpandBtnText={
                props.Content.Translations &&
                props.Content.Translations.expandbuttontext
              }
            >
              <Linklist
                Modifiers={{
                  'c-linklist--collapsible': true,
                  'c-linklist--no-icons': true,
                }}
                {...SiteFooter.LinkListSecond}
                Heading={null}
              />
            </CollapsiblePanel>
          )}

          {SiteFooter.LinkListThird && SiteFooter.LinkListThird.Links && (
            <CollapsiblePanel
              Id="panel3"
              className="c-collapsible-panel"
              Heading={
                SiteFooter.LinkListThird
                  ? SiteFooter.LinkListThird.Heading
                  : null
              }
              Language={SiteFooter.LinkListThird.HeadingLanguage}
              ExpandBtnText={
                props.Content.Translations &&
                props.Content.Translations.expandbuttontext
              }
            >
              <Linklist
                Modifiers={{
                  'c-linklist--collapsible': true,
                  'c-linklist--no-icons': true,
                }}
                {...SiteFooter.LinkListThird}
                Heading={null}
              />
            </CollapsiblePanel>
          )}
        </div>
      )}
      <div className="c-footer__site">
        <div className="row">
          <div className="columns">
            <div className="c-footer__site__inner">
              <div className="c-footer__site__inner__bottom-links c-linklist c-linklist--site-footer">
                <Linklist
                  {...SiteFooter}
                  Modifiers={{
                    ...props.siteFooterLinkListModifiers,
                  }}
                  ListChildren={
                    SiteFooter.CookieSettingsLinkEnabled && (
                      <li>
                        <div className="ot-container">
                          <button
                            id="ot-sdk-btn"
                            className="ot-sdk-show-settings"
                          >
                            Inställningar för kakor
                          </button>
                        </div>
                      </li>
                    )
                  }
                  Heading={null}
                />
                {(!FooterLogo || !ShowLogo) && SiteFooter.SiteInformation && (
                  <div className="c-footer__logo">
                    <div className="c-footer__logo__text" dangerouslySetInnerHTML={{__html: SiteFooter.SiteInformation}}>
                    </div>
                  </div>
                )}
                {FooterLogo && ShowLogo && (
                  <div className="c-footer__logo">
                    <FooterLogo logosr={props.SiteLogoSr} id="footer-logo" link={SiteFooter.LogotypeLinkUrl} logotext={SiteFooter.LogoText} prefixId={'__f'} />
                    {SiteFooter.SiteInformation && (
                      <div className="c-footer__logo__text" dangerouslySetInnerHTML={{ __html: SiteFooter.SiteInformation }}>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  siteFooterLinkListModifiers: {
    'c-linklist--inline': true,
  },
};

export default Footer;
