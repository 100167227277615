import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';

import qs from 'qs';
import { readCookie } from '../Base/Cookies';
import { log } from '../Helpers/helpers';

export const CookieContext = createContext({});

export const onCookieChange = (cookieName, callback) => {
  // store prev value in mutable context
  const prevContext = useRef(null);
  // read from "global" cookie context
  const context = useContext(CookieContext);
  // check if the cookie we want is allowed
  const isAllowed = context.isAllowed(cookieName);
  // if this is the first time we run - set prev and current to the same
  if (prevContext.current === null) {
    prevContext.current = isAllowed;
  }
  // did the value change? isAllowed is updated from context state.
  // run a callback if the value has changed
  if (prevContext.current !== isAllowed) {
    callback({ isAllowed });
  }
  // and update prev context to current
  prevContext.current = isAllowed;
  // return if the cookie is allowed
  return isAllowed;
};

const CookieContextProvider = (props) => {
  const [status, setStatus] = useState('DONE');
  const retryCount = useRef(0);
  const timer = useRef(null);

  log('info')(`status is ${status}.`)

  const checkCpScript = () => {
    if (retryCount.current >= 20) {
      log('warn')('Did not load CookiePro.');
      setStatus('ERROR');
      return;
    }

    //Set flag if script has been loaded
    if ('OneTrust' in window) {
      log('info')('CookiePro successfully loaded.');
      setStatus('DONE');
      return;
    }

    log('info')('Resetting timer for check of script status.');
    timer.current = setTimeout(checkCpScript, 100 * ++retryCount.current);
  };

  useEffect(() => {
    if (props.cookiePro === true && status === 'PENDING') {
      checkCpScript();
    }
  }, [status]);

  let initialState = {
    C0001: true,
    C0002: false,
    C0003: false,
    C0004: false,
    C0005: false,
  };

  const handleCookieProParsing = () => {
    if (status === 'PENDING') {
      log('info')('Waiting for a cookie pro cookie.');
      return;
    }

    const OptanonConsent = readCookie('OptanonConsent');
    if (typeof OptanonConsent === 'undefined') {
      log('info')('No OptanonConsent cookie found.');
      setStatus('PENDING');
      return;
    }

    const parsedCookie = qs.parse(OptanonConsent);
    if (typeof parsedCookie.groups === 'undefined') {
      log('info')('No groups found in OptanonConsent cookie.');
      setStatus('PENDING');
      return;
    }

    parsedCookie.groups
      .split(',')
      .forEach(function addGroupToInitialState(group) {
        let [g, v] = group.split(':');
        initialState[g] = Boolean(Number(v));
      });

    log('info')('Parsed and updated values for cookie pro cookie.');
  };

  const handleLegacyAcceptedCookie = () => {
    const acceptedLegacyCookieValue = JSON.parse(readCookie('acceptedCookie'));
    initialState = {
      C0001: true,
      C0002: acceptedLegacyCookieValue,
      C0003: acceptedLegacyCookieValue,
      C0004: acceptedLegacyCookieValue,
      C0005: acceptedLegacyCookieValue,
    };
  };

  if (props.cookiePro === true) {
    handleCookieProParsing();
  } else {
    handleLegacyAcceptedCookie();
  }

  const [C0001, setC0001] = useState(initialState.C0001); // Nödvändiga
  const [C0002, setC0002] = useState(initialState.C0002); // Analyskakor
  const [C0003, setC0003] = useState(initialState.C0003); // Funktionella
  const [C0004, setC0004] = useState(initialState.C0004); // Riktade kakor
  const [C0005, setC0005] = useState(initialState.C0005); // Sociala medier kakor

  const Cookies = {
    StatusMessageCookie: C0003,
    PanicButtonCookie: C0003,
    PowerBi: C0003,
    ShowSensitiveImagesCookie: C0003,
    ReadingAid: C0003,
    Survey: C0003,
  };

  function isAllowed(cookieName) {
    return Cookies[cookieName];
  }

  const value = {
    isAllowed,
    cookies: Cookies,
    groups: {
      C0001,
      C0002,
      C0003,
      C0004,
      C0005,
    },
  };

  function onConsentChange(event) {
    // event.detail holds array with groups that has consent given
    // example: ["C0001", "C0002"]
    const consentGiven = event.detail;
    log('info')(`Consent was changed to ${consentGiven}`);
    setC0001(true);
    setC0002(consentGiven.includes('C0002'));
    setC0003(consentGiven.includes('C0003'));
    setC0004(consentGiven.includes('C0004'));
    setC0005(consentGiven.includes('C0005'));
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('consent.onetrust', onConsentChange);
    }
    return () => {
      window.removeEventListener('consent.onetrust', onConsentChange);
    };
  });

  return (
    <CookieContext.Provider value={value}>
      {props.children}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
