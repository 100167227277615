import React from 'react';

const Complimentnav = (props) => {
  if (!props.ManualLinks || props.ManualLinks.length === 0) return null;

  const children = props.ManualLinks.map((item, index) => {
    let classColor = 'c-complimentnav__item';

    if (item.Theme && item.Theme !== '') {
      classColor = `c-complimentnav__item c-complimentnav__item--${item.Theme}`;
    }

    return (
      <li key={`compliment${index}`} className={classColor}>
        <a href={item.Url}>
          <span>
            <span className="c-complimentnav__item__icon">
              <span className="c-complimentnav__item__icon__inner"></span>
            </span>
            {item.Text}
          </span>
        </a>
      </li>
    );
  });

  return (
    <>
      {props.complimentnavHeading && <span>{`Mer om ${props.Text}:`}</span>}
      <ul className="c-complimentnav">
        {props.ManualLinksLabel && (
          <li className="c-complimentnav__item">{props.ManualLinksLabel}:</li>
        )}
        {children}
      </ul>
    </>
  );
};

export default Complimentnav;
