const API_BASE = '';

const fetchbyURL = (url) =>
  fetch(url)
    .then((response) => { return response; })
    .catch((error) => { throw error; });

const postData = (endPoint, data) =>
  fetch(endPoint, {
    method: 'POST',
    body: data,
  })
    .then((response) => response)
    .catch((error) => console.error('Error:', error));

export const search = (query) => fetchbyURL(`${API_BASE}${query}`);
export const post = (endPoint, data) =>
  postData(`${API_BASE}${endPoint}`, data);
export const autocomplete = (query) => fetchbyURL(`${API_BASE}${query}`);
