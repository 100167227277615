import React from 'react';
import T from 'prop-types';

const BlockProperty = (props) => {
  const editAttributes = {
    [global.PWTPlatform.EditAttributes[0]]: props.propertyName,
    [global.PWTPlatform.EditAttributes[1]]: true,
    'data-contentgroup': true,
    className: 'epi-editContainer',
  };

  return <div {...editAttributes}>{props.children}</div>;
};

/**
 * Will render a (list of) Block, will spread all other props to each block.
 */
const Block = ({
  blocks,
  propertyName,
  children,
  currentPageProps,
  stylePropertyName,
  ...props
}) => {
  if (!blocks && global.PWTPlatform.IsEditMode)
    return <BlockProperty propertyName={propertyName} />;

  if (!blocks) return null;

  let blockList = [];
  if (Object.prototype.toString.call(blocks) === '[object Array]') {
    blockList = blocks;
  } else {
    blockList.push(blocks);
  }

  const blockItems = blockList.map((block, index) => {
    if (!block) return;
    const uniqueBlockId = `${propertyName}${block.BlockId}-${index}`;
    if (global.PWTPlatform.IsEditMode) {
      return (
        <div
          key={uniqueBlockId}
          data-contentgroup
          data-epi-block-id={block.BlockId}
          data-epi-content-name={block.Heading}
        >
          {React.cloneElement(children, {
            ...block,
            key: index,
            index,
            UniqueBlockId: uniqueBlockId,
            CurrentPageProps: currentPageProps,
            PropertyName: stylePropertyName || propertyName,
            ...props,
            ...children.props,
          })}
        </div>
      );
    } else {
      return React.cloneElement(children, {
        ...block,
        key: uniqueBlockId,
        index,
        UniqueBlockId: uniqueBlockId,
        CurrentPageProps: currentPageProps,
        PropertyName: stylePropertyName || propertyName,
        ...props,
        ...children.props,
      });
    }
  });

  if (global.PWTPlatform.IsEditMode) {
    return (
      <BlockProperty propertyName={propertyName}>{blockItems}</BlockProperty>
    );
  } else {
    return blockItems;
  }
};

Block.propTypes = {
  blocks: T.any,
  propertyName: T.string,
  children: T.node,
  currentPageProps: T.any,
};

export default Block;
