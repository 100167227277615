import React from 'react';
import ReadingAid from '../../platformui/ReadingAid';
import LanguagePanel from '../../platformui/LanguagePanel';
import GlobalSearch from '../../platformui/Search/globalsearch';
import Spinner from '../../platformui/Spinner';

const UmoTools = (props) => {
  return (
    <ul className="c-header-tools hide-for-print">
      <li>
        <ReadingAid
          {...props}
          Spinner={Spinner}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--reading-aid"></i>
          }
          toggleButtonClasses={'c-header-tools__toolbtn'}
          activateButtonClass={'c-forms__button'}
        />
      </li>
      {props.LanguagePanel && props.LanguagePanel.EnableLanguagePanel && (
        <li className="show-for-large">
          <LanguagePanel
            {...props}
            ButtonSVG={
              <i className="c-header-tools__icon c-header-tools__icon--languages"></i>
            }
            toggleButtonClasses={'c-header-tools__toolbtn'}
          />
        </li>
      )}

      {props.FindReceptionLink && (
        <li>
          <a
            href={props.FindReceptionLink}
            className="c-header-tools__toolbtn"
            data-cy="umoyoumo-find-reception-link"
          >
            <div className="c-header-tools__icon c-header-tools__icon--find-reception"></div>
            <span>
              {props.Content.Translations &&
                props.Content.Translations.carecenters}
            </span>
          </a>
        </li>
      )}
      <li>
        <GlobalSearch
          {...props}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--search"></i>
          }
        />
      </li>
    </ul>
  );
};

export default UmoTools;
