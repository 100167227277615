import { React, useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { CookieContext } from '../../platformui/Context/cookies';
import { readCookie, createCookie } from '../../platformui/Base/Cookies';

const PanicButton = ({ Label, Link, Translations }) => {
  const cookieContext = useContext(CookieContext)
  const [isVisible, setVisible] = useState(true);
  const [isMouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    // Since we always SSR with visible true it's only when it's visible we need/can check if the cookie actually says no
    // To avoid hydration conflicts, we won't initially render as not visible even if the cookie says so, instead doing it here
    // after the initial render.
    isVisible && setVisible(readCookie('panicbtn') !== 'false');
  });

  const togglePanicButton = (e) => {
    e.preventDefault();
    if (cookieContext.isAllowed('PanicButtonCookie')) {
      createCookie('panicbtn', !isVisible, 365);
    }
    setVisible(!isVisible)
  }

  const classes = classNames('c-panic-button', {
    'c-panic-button--is-visible': isVisible,
    'c-panic-button--is-hover': isMouseOver,
  });

  return (
    <div className={classes}>
      <div className="c-panic-button__inner--before">
      <div className="c-panic-button__inner">
        <button
          type="button"
          onClick={(e) => togglePanicButton(e, cookieContext)}
          className="c-panic-button__button"
        >
          <span className="show-for-sr">
            {Translations.panicbuttonclosepanel}
          </span>
        </button>
        <a
          href={Link}
          className="c-panic-button__text"
          tabIndex="0"
        >
          {Label && <span>{Label}</span>}
        </a>
        <button
          className="c-panic-button__button-expand"
          type="button"
          onMouseOver={() => setMouseOver(true)}
          onMouseOut={() => setMouseOver(false)}
          onClick={(e) => togglePanicButton(e)}
        >
          <span className="show-for-sr">
            {Translations.panicbuttonopenpanel}
          </span>
        </button>
        </div>
      </div>
    </div>
  );
}

export default PanicButton;
