import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';

import { CookieContext } from '../Context/cookies';
import { createCookie, readCookie } from '../Base/Cookies';

const StatusMessage = (props) => {
  const cookieContext = useContext(CookieContext);

  const [isOpen, setIsOpen] = useState(false);
  const toggleMessage = () => {
    if (cookieContext.isAllowed('StatusMessageCookie')) {
      createCookie('statusMessageOpen-' + props.message.MessageId, !isOpen);
    }
    setIsOpen(!isOpen);
  };

  const checkStorage = () => {
    const cookieValue = JSON.parse(
      readCookie('statusMessageOpen-' + props.message.MessageId)
    );

    cookieValue === null && props.isExpanded
      ? setIsOpen(true)
      : setIsOpen(cookieValue);
  };

  useEffect(() => {
    checkStorage();
  }, []);

  return (
    <div className="statusmessage">
      <div className="statusmessage__heading">
        <div className="statusmessage__icon-container">
          <span className="statusmessage__icon" aria-hidden="true" />
        </div>

        <h2>
          <span className="statusmessage__heading__text">
            {props.message.Title}
          </span>
          {props.message.RegionLabel && (
            <span className="statusmessage__heading__region_label">
              <span className="show-for-sr">.Det här meddelandet avser </span>
              <span>{props.message.RegionLabel}</span>
            </span>
          )}
        </h2>

        <button
          onClick={toggleMessage}
          type="button"
          className="statusmessage__expand-button"
          aria-expanded={isOpen}
          aria-label={
            isOpen
              ? props.translations.hidemessage
              : props.translations.showmessage
          }
        >
          <span className="statusmessage__expand-button__text">
            {isOpen
              ? props.translations.hidemessage
              : props.translations.showmessage}
          </span>
          <span
            className={classNames({
              'statusmessage__expand-button__icon': true,
              'statusmessage__expand-button__icon--is-open': isOpen,
            })}
            aria-hidden="true"
          />
        </button>

        <div
          className={classNames({
            statusmessage__content: true,
            'statusmessage__content--visible': isOpen,
          })}
        >
          <div
            dangerouslySetInnerHTML={{ __html: props.message.Message }}
          ></div>

          {props.message.Author && props.message.StartDate && (
            <div className="statusmessage__author">
              {props.message.Author} , {props.message.StartDate.slice(0, 10)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusMessage;
