import React, { Fragment } from 'react';
import classNames from 'classnames';
import withToolbarButtonList from '../Containers/withToolbarButtonList';

const LanguagePanel = (props) => {
  const { ButtonSVG, LanguagePanel, toggleButtonClasses, isVisible } = props;

  const languagePanelClasses = classNames({
    'c-language-panel': true,
    'c-language-panel--is-opened': props.isVisible,
  });

  const languagePanelInner = classNames({
    'c-language-panel__inner': true,
    'c-language-panel__inner--no-button': !LanguagePanel.Link,
  });

  return (
    <Fragment>
      <div
        className={languagePanelClasses}
        id="language-panel"
        aria-hidden={!isVisible}
      >
        <div className="row">
          <div className="columns">
            <div className={languagePanelInner}>
              <div
                dangerouslySetInnerHTML={{ __html: LanguagePanel.Text }}
              ></div>
              <a href={LanguagePanel.Link} className="c-forms__button">
                {LanguagePanel.Label}
              </a>
            </div>
          </div>
        </div>
      </div>

      <a
        className={toggleButtonClasses}
        onClick={() => props.handleVisible(null, 'language-panel')}
        role="button"
        aria-controls="language-panel"
        aria-expanded={isVisible}
        tabIndex="0"
      >
        {ButtonSVG}
        <span>
          {props.Content.Translations && props.Content.Translations.languages}
        </span>
      </a>
    </Fragment>
  );
};

export default withToolbarButtonList(LanguagePanel);
