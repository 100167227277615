import React, { useState, Component, useEffect } from 'react';
import classNames from 'classnames';
import Topnav from '../Topnav';
import Mobile from '../Mobilenav';
import AlertList from '../AlertList';
import Burger from './burger';
const withToggleHeaderTools = (WrappedComponent) => {
  return class ToggleHeaderTools extends Component {
    state = {
      isToolsVisible: false,
    };
    tools = [];

    addToolComponent(component) {
      this.tools.push(component);
    }

    handleToolsVisibility(isToolsVisible, height) {
      this.tools.forEach((tool) => {
        tool.setState({
          isVisible: false,
        });
      });

      this.setState({
        isToolsVisible,
        height: isToolsVisible ? height : null,
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          tools={this.tools}
          addToolComponent={(component) => this.addToolComponent(component)}
          handleToolsVisibility={(isToolsVisible, height) =>
            this.handleToolsVisibility(isToolsVisible, height)
          }
        />
      );
    }
  };
};

const Header = (props) => {
  let timerMobileNav = null;
  const [mobileNavVis, setMobileNavVis] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);

  useEffect(() => {
    if (props.isFullOverlayInMobile) {
      mobileNavVis && document.body.classList.add('no-scroll');
      !mobileNavVis && document.body.removeAttribute('class');
    }
  });

  const handleClick = (e) => {
    e.preventDefault();
    setMobileNavVis(!mobileNavVis);
    clearTimeout(timerMobileNav);
    timerMobileNav = setTimeout(() => {
      setAnimationStart(!animationStart);
    }, 5);
  };

  const {
    Logo,
    Tools,
    ToolsBottom,
    LangSelectorSmall,
    isWideNav,
    isFullWidth,
    meganavHasHeading,
  } = props;

  const headerClasses = classNames({
    'c-header': true,
    'c-header--is-tools-visible': props.isToolsVisible,
    'c-header--is-navigation': mobileNavVis,
    'c-header--is-animating': animationStart,
    'c-header--showbuildinfo': props.BuildInfo,
  });

  const headerNavClasses = classNames({
    'c-header__navigation': true,
    'c-header__navigation--is-full-overlay': props.isFullOverlayInMobile,
  });

  const TopNavComponent = (
    <Topnav
      Children={props.MainNavigation}
      LoadBlocks={props.LoadBlocks}
      isWideNav={isWideNav}
      isFullWidth={isFullWidth}
      meganavHasHeading={meganavHasHeading}
      complimentnavHeading={props.complimentnavHeading}
      closeTranslation={props.Content.Translations.close}
    />
  );

  return (
    <header>
      <div className={headerClasses}>
        <div className="c-header__top" style={{ paddingTop: props.height }}>
          <div className="c-header__top__inner">
            <div className="c-header__main">
              {Logo && <Logo {...props} logosr={props.SiteLogoSr} />}

              {Tools && (
                <Tools
                  {...props}
                  mobileNavVis={mobileNavVis}
                  animationStart={animationStart}
                />
              )}
              {(props.MainNavigation || props.SecondaryNavigation) &&
                !props.hideHamburgerMenuInHeader && (
                  <Burger
                    burgerBtnClassname="c-header__burger"
                    handleHamburgerClick={(e) => handleClick(e)}
                    mobileNavVis={mobileNavVis}
                    {...props}
                  />
                )}
              {props.topMenuSearch && (
                <button
                  type="button"
                  aria-controls="panel-globalsearch--mobile"
                  className="c-header__search-mobile"
                  onClick={() => props.toggle('search')}
                  aria-expanded={props.activeTool === 'search'}
                >
                  <i className="search-icon" />
                  <span className="show-for-sr">
                    {props.Content.Translations.search}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
        {ToolsBottom && (
          <ToolsBottom
            {...props}
            mobileNavVis={mobileNavVis}
            animationStart={animationStart}
            handleHamburgerClick={(e) => handleClick(e)}
          />
        )}

        <div className={headerNavClasses} data-cy="header-navigation">
          <nav
            className="c-mobilenav-wrapper"
            id="mobile-nav"
            aria-hidden={!mobileNavVis}
            data-cy="ui-mobile-nav"
          >
            {mobileNavVis && (
              <div className="c-mobilenav">
                {props.isFullOverlayInMobile && (
                  <div className="c-mobilenav__header">
                    <h2>Meny</h2>
                    <Burger
                      text={props.Content.Translations.close}
                      burgerBtnClassname="c-header__burger"
                      handleHamburgerClick={(e) => handleClick(e)}
                      mobileNavVis={mobileNavVis}
                      {...props}
                    />
                  </div>
                )}
                <Mobile
                  isFullOverlayInMobile={props.isFullOverlayInMobile}
                  mobileNavHasFooterLinks={
                    props.mobileNavHasFooterLinks && props.SiteFooter
                  }
                  Children={props.MainNavigation}
                  PrefixId="mobile"
                  CurrentPageId={props.PageId}
                  ExpandBtnText={
                    props.Content.Translations &&
                    props.Content.Translations.expandbuttontext
                  }
                  chevronMobile={props.chevronMobile}
                />
              </div>
            )}
            {mobileNavVis && props.SecondaryNavigation && (
              <div className="c-metanav">
                <Mobile
                  Children={props.SecondaryNavigation}
                  PrefixId="meta"
                  CurrentPageId={props.PageId}
                  ExpandBtnText={
                    props.Content.Translations &&
                    props.Content.Translations.expandbuttontext
                  }
                  chevronMobile={props.chevronMobile}
                  ComponentClassName="c-metanav"
                />
              </div>
            )}
            {mobileNavVis && props.CareGiverItemSettings?.TopMenuItemEnabled == true  && (
              <div className="c-metanav">
                <Mobile
                  Icon={"care-giver-icon-mobile"}
                  Url={props.CareGiverItemSettings.TopMenuItemURL}
                  Text={props.CareGiverItemSettings.TopMenuItemText}
                  PrefixId="meta"
                  id='care-giver-link'
                  CurrentPageId={props.PageId}
                  ComponentClassName="c-metanav"
                />
              </div>
            )}
            {props.renderAfterMobileMenu(mobileNavVis)}
          </nav>
          {props.noTopNavRow ? (
            TopNavComponent
          ) : (
            <div className="row">
              <div className="columns">{TopNavComponent}</div>
            </div>
          )}
        </div>
        {LangSelectorSmall && (
          <div className="row hide-for-large">
            <div className="medium-8 medium-centered">
              <LangSelectorSmall {...props} />
            </div>
          </div>
        )}
      </div>
      <AlertList {...props} />
    </header>
  );
};

Header.defaultProps = {
  renderAfterMobileMenu: () => {},
};

export default withToggleHeaderTools(Header);
