import React from 'react';
import classNames from 'classnames';

const LangSelectorBig = ({ Languages }) => {
  if (!Languages) return null;

  return (
    <ul className="c-langselector-big">
      {Languages.map((lang, key) => (
        <li
          key={key}
          className={classNames({
            'c-langselector__item': true,
          })}
        >
          <a href={lang.Href}>
            <span className="c-langselector__item__lang" dir={lang.Dir}>
              {lang.Name}
            </span>
            <span className="c-langselector__item__lang--sv">
              {lang.NameSwe}
            </span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LangSelectorBig;
