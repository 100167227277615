import { Fragment, React } from 'react';
import Item from './item';

const Mobile = (props) => {
  const level = typeof props.Level === 'undefined' ? 0 : props.Level + 1;
 
  //Default component class name is c-mobilenav
  const componentClassName = props.ComponentClassName
    ? props.ComponentClassName
    : 'c-mobilenav';
                            
  let children = [];

  if (props.subMenuData) {
    children = props.subMenuData.map((child, index) => (
      <Item
        key={`mobile${child.PageId}${index}`}
        {...child}
        Id={`${props.PrefixId}-${child.PageId}-${level}-${index}`}
        Level={level}
        PrefixId={props.PrefixId}
        ComponentClassName={componentClassName}
        CurrentPageId={props.CurrentPageId}
        chevronMobile={props.chevronMobile}
        ExpandBtnText={props.ExpandBtnText}
      />
    ));
  }

   
  if (props.Children) {
    children = props.Children.map((child, index) => (
      <Item
        key={`mobile${child.PageId}${index}`}
        {...child}
        Id={`${props.PrefixId}-${child.PageId}-${level}-${index}`}
        Level={level}
        PrefixId={props.PrefixId}
        ComponentClassName={componentClassName}
        CurrentPageId={props.CurrentPageId}
        chevronMobile={props.chevronMobile}
        ExpandBtnText={props.ExpandBtnText}
      />
    ));
  }

  let careGiverLink = [];
  if (props.Icon) {
    careGiverLink=(
      <Item
        Icon={props.Icon}
        CurrentPageId={props.CurrentPageId}
        PrefixId={props.PrefixId}
        ComponentClassName={componentClassName}
        Url={props.Url}
        Text={props.Text}
      />
    );
    }

  let elmAttributes = {
    id: props.Id ? props.Id : null,
    'aria-hidden': typeof props.isOpen !== 'undefined' ? !props.isOpen : null,
  };

  const FooterLinks = () => {
    const { LinkListFirst, LinkListSecond, LinkListThird } =
      props.mobileNavHasFooterLinks;

    const concatLinks = (...arrays) =>
      [].concat(...arrays.filter(Array.isArray));

    const links = concatLinks(
      LinkListFirst.Links,
      LinkListSecond.Links,
      LinkListThird.Links
    );

    return (
      <ul className="c-mobilenav__footer-links">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.Href}>{link.Text}</a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Fragment>
      <ul className={`${componentClassName}__list`} {...elmAttributes} data-cy="ui-mobile-nav-list">
        {children}
        {careGiverLink}
      </ul>
      {props.mobileNavHasFooterLinks && <FooterLinks />}
    </Fragment>
  );
};

export default Mobile;
