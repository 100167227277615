import React from 'react';
import classNames from 'classnames';

const BuildInfo = (props) => {
  if (!props || !props.BuildInfo) return false;
  const classes = classNames({
    'c-buildinfo': true,
  });

  return (
    <div className={classes}>
      <ul className="c-buildinfo__list">
        <li>
          <span>Environment:</span> {props.BuildInfo.Environment}
        </li>
        <li className="visible-mobile">
          <span>Server:</span> {props.BuildInfo.Server}
        </li>
        <li>
          <span>Branch:</span> {props.BuildInfo.Branch}
        </li>
        <li>
          <span>Release:</span> {props.BuildInfo.ReleaseNumber}
        </li>
        <li>
          <span>Built:</span> {props.BuildInfo.Built}
        </li>
        <li className="visible-mobile">
          <span>Request id:</span>{' '}
          {props.BuildInfo.Identifier &&
            props.BuildInfo.Identifier.substring(0, 8)}
        </li>
        <li>
          <span>Commit:</span>{' '}
          {props.BuildInfo.Commit && props.BuildInfo.Commit.substring(0, 8)}
        </li>
      </ul>
    </div>
  );
};

export default BuildInfo;
