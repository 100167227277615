import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import withRelevantDate from '../Containers/withRelevantDate';
import StatusMessage from '../StatusMessage';

const AlertMessagesList = (props) => {
  if (!props.Messages || props.Messages.length < 1) return null;

  return (
    <div className="c-alertlist">
      {props.Messages.map((message, index) => (
        <StatusMessage
          key={index}
          message={message}
          translations={props.Content.Translations}
          isExpanded={props.Messages.length === 1}
        />
      ))}
    </div>
  );
};

AlertMessagesList.propTypes = {
  Messages: arrayOf(
    shape({
      MessageId: string.isRequired,
      Author: string,
      /** StartDate is set to a string from `withRelevantDate` container */
      StartDate: string,
      Title: string.isRequired,
      Message: string.isRequired,
    })
  ),
};

export default withRelevantDate(AlertMessagesList);
