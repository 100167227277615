import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as serviceWorker from './platformui/Base/ServiceWorker/serviceWorker';
import * as Render from './platformui/Base/Render';
import Layout from './framework/layouts/layout';

import AsyncPageTypes from './components/async-pagetypes/async';
import Logo from './components/c-logosvg';
import LogoUmo from './components/c-logosvg/umo-footer';

import './framework/scss/app.scss';

// We import() just to create the chunks, but must not actually try to load the chunks here, so we
// wrap the import() in a lazy and then just never use it.
React.lazy(() => import(/* webpackChunkName: "preload" */ './preload.js'));
React.lazy(() => import(/* webpackChunkName: "editor" */ './editor.js'));

const Preview = React.lazy(() =>
  import(/* webpackChunkName: "preview" */ './framework/layouts/preview')
);

// Additional requires that are not implicity loaded into the dependency graph. May require custom loading in webpack.config.js.
require.resolve('./umo-manifest.json');
require.resolve('./youmo-manifest.json');
require.context('./framework/favicons/', true, /\.png$/);

const main = () => {
  const staticLogo = Render.staticComponent(Logo, 'header-logo');
  const staticLogoUmo = Render.staticComponent(LogoUmo, 'footer-logo');

  let appNode = document.getElementById('app')?.firstChild;
  let previewNode = document.getElementById('preview')?.firstChild;

  let hydrateOrRender = appNode?.firstChild ? hydrate : render; // if <div id='app'><div></div></div>, SSR is disabled and we render instead of hydrate.

  appNode &&
    hydrateOrRender(
      <Router>
        <Route
          path="*"
          render={(routeProps) => (
            <Layout
              routeProps={routeProps}
              Logo={staticLogo}
              cookies={document.cookie}
              PageTypeRenderer={AsyncPageTypes}
              LogoUmo={staticLogoUmo}
            />
          )}
        />
      </Router>,
      appNode
    );

  previewNode &&
    render(
      <Router>
        <Suspense fallback={<div>Laddar...</div>}>
          <Route
            path="*"
            render={(routeProps) => <Preview {...routeProps} />}
          />
        </Suspense>
      </Router>,
      previewNode
    );
};

serviceWorker.register();
main();
