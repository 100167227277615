import React from 'react';
import classNames from 'classnames';
import SearchField from './searchfield';
import withToolbarButtonList from '../Containers/withToolbarButtonList';

const GlobalSearch = (props) => {
  const { ButtonSVG } = props;
  const globalSearchClasses = classNames({
    'c-globalsearch': true,
    'c-globalsearch--is-opened': props.isVisible,
  });
  return (
    <div>
      <div
        className={globalSearchClasses}
        id="global-search"
        data-cy="globalsearch-panel"
        aria-hidden={!props.isVisible}
      >
        <div className="row">
          <div className="columns">
            {props.HasSearch && (
              <div className="c-globalsearch__inner" role="search">
                <form
                  action={
                    props.SearchSettings && props.SearchSettings.SearchUrl
                  }
                  method="get"
                  autoComplete="off"
                >
                  <div className="c-globalsearch__form-component">
                    <label
                      className="c-globalsearch__field__label icon-search"
                      htmlFor="globalsearch"
                    >
                      <span className="show-for-sr">
                        {props.SearchSettings.SearchPlaceholder}
                      </span>
                    </label>

                    <SearchField
                      id="globalsearch"
                      className="c-globalsearch"
                      searchUrl={
                        props.SearchSettings && props.SearchSettings.SearchUrl
                      }
                      placeholder={props.SearchSettings.SearchPlaceholder}
                      hasNoAutocomplete={props.hasNoAutocomplete}
                      hasQuickSearch={props.hasQuickSearch}
                    />

                    <button
                      type="submit"
                      className="c-globalsearch__field__button"
                    >
                      Sök
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {ButtonSVG && (
        <a
          href="#global-search"
          tabIndex="0"
          role="button"
          className="c-header-tools__toolbtn c-header-tools__toolbtn--search"
          data-cy="header-tools-search-btn"
          onClick={(e) => props.handleVisible(e, 'global-search')}
          aria-controls="global-search"
          aria-labelledby="description-search"
          aria-expanded={props.isVisible}
        >
          {ButtonSVG}
          <span id="description-search">
            {props.Content.Translations && props.Content.Translations.search}
          </span>
        </a>
      )}
    </div>
  );
};

export default withToolbarButtonList(GlobalSearch);
