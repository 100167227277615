import React, { Component } from 'react';
import queryString from 'qs';
import { autoComplete } from '../Actions/search';
import { search } from '../Reducers/search';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default (
  WrappedComponent,
  autoCompletePath = '/api/v2/search/autocomplete',
  summaryHits = (hits) => hits,
  selectItemValue = (item) => item,
  currentIndexItem = (item) => item,
  defaultIndex = -1,
  queryParamString = '',
  minStringLength = 1
) => {
  class Container extends Component {
    quickSearch =
      typeof window !== 'undefined'
        ? window.__PRELOADED_STATE__?.SearchSettings?.QuickSearchUrl
        : null;
    timer = null;
    minStringLength = this.handleMinStringLenght(minStringLength);
    itemFallback = this.props.suggestionsFallback || [];
    autoCompletePath =
      this.props.SearchApiAutoCompleteUrl ||
      this.quickSearch ||
      autoCompletePath;

    //Set initial state
    state = search({
      isLoading: null,
      isVisible: false,
      index: defaultIndex,
      currentIndexObject: null,
      selectedObject: null,
      Hits: [],
      value: this.props.defaultValue ? this.props.defaultValue : '',
    });

    wrappedComponent = React.createRef();

    //TODO: Change to redux
    dispatch(action) {
      this.setState(search(this.getState(), action));
    }

    getState() {
      return this.state;
    }

    hasHits(hits) {
      return summaryHits(hits) && summaryHits(hits).length > 0;
    }

    handleKeyboard(e) {
      if (
        !summaryHits(this.state.Hits) ||
        summaryHits(this.state.Hits).length === 0
      )
        return;

      switch (e.keyCode) {
        case 13:
          if (this.state.index < 0) return true;
          this.dispatch({
            type: 'OPTION_SELECT_AUTOCOMPLETE',
            selectedValue: selectItemValue(
              summaryHits(this.state.Hits)[this.state.index]
            ),
            selectedObject: summaryHits(this.state.Hits)[this.state.index],
          });
          return true;
        case 38:
          e.preventDefault();

          if (this.state.index < 1) {
            this.dispatch({
              type: 'OPTION_INDEX_AUTOCOMPLETE',
              index: summaryHits(this.state.Hits).length - 1,
              currentIndexObject: summaryHits(this.state.Hits)[
                summaryHits(this.state.Hits).length - 1
              ],
              selectedValue: currentIndexItem(
                summaryHits(this.state.Hits)[
                  summaryHits(this.state.Hits).length - 1
                ]
              ),
            });
            return false;
          }

          this.setState(
            (state) => {
              return { index: state.index - 1 };
            },
            () => {
              this.dispatch({
                type: 'OPTION_INDEX_AUTOCOMPLETE',
                index: this.state.index,
                currentIndexObject: summaryHits(this.state.Hits)[
                  this.state.index
                ],
                selectedValue: currentIndexItem(
                  summaryHits(this.state.Hits)[this.state.index]
                ),
              });
            }
          );

          return false;

        case 40:
          e.preventDefault();

          if (this.state.index >= summaryHits(this.state.Hits).length - 1) {
            this.dispatch({
              type: 'OPTION_INDEX_AUTOCOMPLETE',
              index: 0,
              currentIndexObject: summaryHits(this.state.Hits)[0],
              selectedValue: currentIndexItem(summaryHits(this.state.Hits)[0]),
            });

            return false;
          }

          this.setState(
            (state) => {
              return { index: state.index + 1 };
            },
            () => {
              this.dispatch({
                type: 'OPTION_INDEX_AUTOCOMPLETE',
                index: this.state.index,
                currentIndexObject: summaryHits(this.state.Hits)[
                  this.state.index
                ],
                selectedValue: currentIndexItem(
                  summaryHits(this.state.Hits)[this.state.index]
                ),
              });
            }
          );

          return false;
        case 27:
          this.dispatch({
            type: 'HIDE_AUTOCOMPLETE',
          });
      }
    }

    search() {}

    handleChange(e) {
      e.persist();
      const value = e.target.value;

      if (value.length >= this.minStringLength) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          const qs = this.handleQueryParamString();
          autoComplete(
            `${this.autoCompletePath}?q=${encodeURI(value)}${qs}`,
            this.itemFallback
          )((action) => {
            this.dispatch({
              ...action,
              currentIndexObject: this.hasHits(action.payload)
                ? summaryHits(action.payload)[defaultIndex]
                : null,
            });
          }, this.getState.bind(this));
        }, 300);
      }

      this.dispatch({
        type: 'ONCHANGE',
        value: value,
        Hits:
          value === '' || value.length < this.minStringLength
            ? []
            : this.state.Hits,
        selectedObject: null,
        index: defaultIndex,
      });
    }

    handleKeyDown(e) {
      //Handle keycodes
      this.handleKeyboard(e);
    }

    handleClickOutside(e) {
      if (
        this.wrappedComponent.current &&
        !this.wrappedComponent.current.contains(e.target)
      ) {
        // Experimentally disable the handling of click events outside of the autocomplete field for the following reasons:
        // 1. It does not appear to be needed, the autocomplete is hidden anyway when clicking outside or tabbing out
        // 2. It complicates debugging
        // 3. I have a theory that the re-rendering of the component causes problems with the modal map becoming detached
        // All this is really too complicated... If future testing detects changed behavior, just revert. /Svante Seleborg 2021-05-28
        // this.closeAutocomplete();
      }
    }

    closeAutocomplete() {
      this.dispatch({
        type: 'HIDE_AUTOCOMPLETE',
      });
    }

    handleOnSelect(item) {
      this.dispatch({
        type: 'OPTION_SELECT_AUTOCOMPLETE',
        selectedValue: selectItemValue(item),
        selectedObject: item,
        currentIndexItem: item,
      });
    }

    clear(value) {
      this.dispatch({
        type: 'ONCHANGE',
        Hits: [],
        value: value,
        index: defaultIndex,
      });
    }

    handleBlur(e) {
      return e;
    }

    handlePropParam(param) {
      if (typeof param === 'string') {
        return param;
      }
      if (typeof param === 'function') {
        return param(this.props);
      }
      if (typeof param === 'object') {
        return queryString.stringify(param);
      }
      return param;
    }

    handleQueryParamString() {
      return this.handlePropParam(queryParamString);
    }

    handleMinStringLenght() {
      return this.handlePropParam(minStringLength);
    }

    handleFocus(e) {
      const value = e.target.value;
      if (value && value.length > this.minStringLength) {
        const qs = this.handleQueryParamString();
        autoComplete(
          `${this.autoCompletePath}?q=${e.target.value}${qs}`,
          this.itemFallback,
          this.requestVerificationToken
        )((action) => {
          this.dispatch({
            ...action,
            currentIndexObject: this.hasHits(action.payload)
              ? summaryHits(action.payload)[defaultIndex]
              : null,
          });
        }, this.getState.bind(this));

        this.clear(value);
      }
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClickOutside.bind(this));
      document.addEventListener(
        'focus',
        this.handleClickOutside.bind(this),
        true
      );
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside.bind(this));
      document.removeEventListener('focus', this.handleClickOutside.bind(this));
    }

    isZeroHits() {
      return Boolean(
        this.props.value &&
          this.state.isLoading === false &&
          this.state.Hits.length === 0 &&
          this.props.value.length > this.minStringLength
      );
    }
    render() {
      return (
        <div ref={this.wrappedComponent}>
          <WrappedComponent
            {...this.props}
            isLoading={this.state.isLoading}
            zeroHits={this.isZeroHits()}
            handleChange={this.handleChange.bind(this)}
            handleKeyDown={this.handleKeyDown.bind(this)}
            handleOnSelect={this.handleOnSelect.bind(this)}
            handleFocus={this.handleFocus.bind(this)}
            handleBlur={this.handleBlur.bind(this)}
            closeAutocomplete={this.closeAutocomplete.bind(this)}
            clearAutoCompleter={this.clear.bind(this)}
            minStringLength={this.minStringLength}
            Suggestions={this.state}
          />
        </div>
      );
    }
  }
  Container.displayName = `withAutocomplete(${getDisplayName(
    WrappedComponent
  )})`;
  return Container;
};
