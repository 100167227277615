import React, { useState, useEffect, useRef } from 'react';
import Item from './item';
import Meganav from '../Meganav';

const Topnav = (props) => {
  const wrapperRef = useRef(null);
  const [currentId, setCurrentId] = useState(null);
  const [blocks, setBlocks] = useState(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleClick = (e, item) => {
    if (!item.Children) return true;

    e.preventDefault();

    setCurrentId(currentId === item.PageId ? null : item.PageId);

    //Load blocks
    props.LoadBlocks((blocks) => setBlocks(blocks));
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current !== null && !wrapperRef.current.contains(e.target)) {
      setCurrentId(null);
    }
  };

  const closeMeganav = () => {
    setCurrentId(null);
  };

  if (!props.Children) return null;

  const children = props.Children.map((item) => {
    return (
      <Item
        key={item.PageId}
        {...item}
        handleClick={(e, key) => handleClick(e, key)}
        isOpened={currentId === item.PageId}
      >
        <Meganav
          {...item}
          isOpened={currentId === item.PageId}
          Blocks={blocks}
          isWideNav={props.isWideNav}
          isFullWidth={props.isFullWidth}
          meganavHasHeading={props.meganavHasHeading}
          complimentnavHeading={props.complimentnavHeading}
          closeTranslation={props.closeTranslation}
          closeMeganav={closeMeganav}
          componentClassName={props.meganavClassName}
        />
      </Item>
    );
  });

  return (
    <nav>
      <ul ref={wrapperRef} className="c-toppnav" data-cy="ui-top-navigation">
        {children}
      </ul>
    </nav>
  );
};

export default Topnav;
