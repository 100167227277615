import React, { useState } from 'react';
import classNames from 'classnames';
import ExpandBtn from './expand-button';
import Mobile from './index';
import { getCurrentRegion } from '../Helpers';

const Anchor = (props) => {
  const linkClasses = classNames({
    [`${props.ComponentClassName}__list__item__link`]:
      props.Type && props.Type !== '',
    [`${props.ComponentClassName}__list__item__link--${props.Type}`]:
      props.Type && props.Type !== '',
  });

  const handleClickCareGiver = (icon) => () => {
    if (icon && window?._paq) {
      window._paq.push([
        'trackEvent',
        'Vardpersonal mobile',
        'Click Vardpersonal 1177 mobile',
        'Click Vardpersonal 1177 Link mobile',
      ]);
    }
  };

  if (!props.Url || props.Url === '') return <span>{props.Text}</span>;
  return (
    <a
      className={linkClasses}
      href={props.Url}
      onClick={handleClickCareGiver(props.Icon)}
    >
      {props.Text}
    </a>
  );
};

const Item = (props) => {
  const [isOpen, setIsOpen] = useState(props.ActivePath);
  const [subMenuData, setSubmenuData] = useState(null);

  const fetchSubMenu = (pageId) => {
    const createRegionSlug = (path) => {
      const region = getCurrentRegion();
      if (!region) return path;

      return `/api/${region.Slug.toLowerCase()}/menuitem/${pageId}`;
    };

    const path = createRegionSlug(`/api/menuitem/${pageId}`);

    fetch(path)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setSubmenuData(data);
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);

    if (!props.Children && !isOpen) {
      fetchSubMenu(props.PageId);
      setSubmenuData(subMenuData);
    }
  };

  const itemClasses = classNames({
    [`${props.ComponentClassName}__list__item`]: true,
    [`${props.ComponentClassName}__list__item--has-children`]:
      props.Children && props.Children.length > 0,
    [`${props.ComponentClassName}__list__item--is-open`]: isOpen,
    [`${props.ComponentClassName}--selected-item`]:
      props.CurrentPageId === props.PageId, //TODO: BUGG i denna: this.props.ActivePath,
  });

  const containerClass =
    `${props.ComponentClassName}__list__item__container` +
    `${props.Icon ? ' care-giver_container' : ''}`;
  return (
    <li className={itemClasses}>
      <div className={containerClass}>
        {props.Icon && <i className={props.Icon}></i>}
        <Anchor {...props} />
        {!props.HideChildrenInMobileMenu && !props.Icon && (
          <ExpandBtn {...props} isOpen={isOpen} handleClick={handleClick} />
        )}
      </div>
      {isOpen && !props.HideChildrenInMobileMenu && (
        <Mobile {...props} subMenuData={subMenuData} isOpen={isOpen} />
      )}
    </li>
  );
};

export default Item;
