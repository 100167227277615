import React from 'react';
import classNames from 'classnames';

const Item = (props) => {
  const ItemClassName = props.itemClassName ? props.itemClassName : 'c-meganav';

  const classes = classNames({
    [`${ItemClassName}__list__item`]: true,
    [`${ItemClassName}__list__item--is-selected`]: props.ActivePath,
  });

  return (
    <li className={classes}>
      <a href={props.Url}>
        <i></i>
        <span>{props.Text}</span>
      </a>
    </li>
  );
};

export default Item;
