import React, { useState } from 'react';
import classNames from 'classnames';
import LangSelectorLangList from './c-langselector-langlist';

const LangSelectorSmall = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const { Languages, PageTypeName } = props;

  if (!Languages || PageTypeName === 'LanguagePage') return null;

  const componentClasses = classNames({
    'c-langselector-small': true,
    'c-langselector-small--is-open': isOpen,
  });

  const selectedLang = Languages.find((lang) => lang.Selected === true);

  return (
    <div className={componentClasses}>
      <button
        type="button"
        onClick={(e) => handleClick(e)}
        className="c-langselector-small__lang"
      >
        <span>
          {selectedLang.Name} -{' '}
          <span className="c-langselector-small__lang__swe">
            {selectedLang.NameSwe}
          </span>
        </span>
      </button>

      <LangSelectorLangList Languages={Languages} />
    </div>
  );
};

export default LangSelectorSmall;
