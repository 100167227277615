import React from 'react';
import classNames from 'classnames';
import LangSelectorLangList from './c-langselector-langlist';
import withToolbarButtonList from '../../platformui/Containers/withToolbarButtonList';

const LangSelector = ({ Languages, ...props }) => {
  if (!Languages) return null;
  const selectedLang = Languages
    ? Languages.find((lang) => lang.Selected === true)
    : 'sv';

  const languageToggleButton = classNames({
    'c-langselector__toggle__btn': true,
    'c-langselector__toggle__btn__text': true,
    'c-langselector__toggle__btn__text--change-lang': true,
    'c-langselector__toggle__btn__text--is-open': props.isVisible,
  });

  const languageTogglePanel = classNames({
    'c-langselector__wrapper': true,
    'c-langselector__wrapper--is-open': props.isVisible,
  });

  const languageToggleWrapper = classNames({
    'c-langselector__toggle': true,
    'c-langselector__toggle--is-open': props.isVisible,
  });

  return (
    <div>
      <div className={languageToggleWrapper}>
        <div className="show-for-large c-langselector__toggle c-langselector__toggle__background">
          <a
            href="#language-selector"
            className={languageToggleButton}
            onClick={(e) => props.handleVisible(e, 'language-selector')}
            aria-controls="language-selector"
            aria-expanded={props.isVisible}
          >
            {selectedLang && (
              <span className="c-langselector__toggle__btn__text__center">
                <span className="c-langselector__toggle__btn__lang">
                  {selectedLang.Name}
                </span>
                <span className="c-langselector__toggle__btn__lang c-langselector__toggle__btn__lang--sv">
                  {selectedLang.NameSwe}
                </span>
              </span>
            )}
          </a>
        </div>
      </div>

      <div
        className={languageTogglePanel}
        id="language-selector"
        aria-hidden={!props.isVisible}
      >
        <div className="row">
          <div className="columns">
            <LangSelectorLangList Languages={Languages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withToolbarButtonList(LangSelector);
