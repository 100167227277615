import React from 'react';
import classNames from 'classnames';
import Item from './item';
import Complimentnav from '../Complimentnav';
import * as Render from '../Base/Render';

const Meganav = (props) => {
  if (!props.Children) return false;

  const componentClassName = props.componentClassName
    ? props.componentClassName
    : 'c-meganav';

  const children = props.Children.map((item, key) => {
    return (
      <Item
        key={`item${key}.PageId}`}
        itemClassName={props.componentClassName}
        {...item}
      />
    );
  });

  const navColumns = classNames({
    columns: true,
    'large-8': !props.isWideNav,
    'large-9': props.isWideNav,
    'large-12': props.isFullWidth,
  });

  const blockColumns = classNames({
    columns: true,
    'large-4': !props.isWideNav,
    'large-3': props.isWideNav,
  });

  return (
    <div
      className={componentClassName}
      id={`subnav-${props.PageId}`}
      aria-hidden={!props.isOpened}
      data-cy="ui-meganav"
    >
      {props.isOpened && (
        <div className={`${componentClassName}__container`}>
          <div className="row">
            <div className={navColumns}>
              <div className="row">
                {props.meganavHasHeading && (
                  <div
                    className={`columns ${componentClassName}__container__header`}
                  >
                    <a href={props.Url}>{props.Heading}</a>
                    <button
                      className={`${componentClassName}__container__header__close-btn`}
                      onClick={props.closeMeganav}
                    >
                      {props.closeTranslation}
                    </button>
                  </div>
                )}

                <ul className={`columns large-12 ${componentClassName}__list`}>
                  {children}
                </ul>
              </div>
            </div>
            <div className={blockColumns}>
              {props.Blocks && (
                <Render.Block blocks={props.TeaserItem} propertyName="Teaser">
                  <Render.BlockTypeRenderer Blocks={props.Blocks} />
                </Render.Block>
              )}
            </div>
          </div>
          <div className="row">
            <div className="columns">
              <Complimentnav {...props} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Meganav;
