import React, { useState } from 'react';

const PanelContext = React.createContext('');

export const PanelState = (props) => {
  const [activePanelId, setActivePanelId] = useState('');

  return (
    <PanelContext.Provider
      value={{
        activePanelId,
        setActivePanelId,
      }}
    >
      {props.children}
    </PanelContext.Provider>
  );
};

export default PanelContext;
