import React from 'react';
import CookieContext from '../../Context/cookies';

export default (WrappedComponent) => {
  const Root = (props) => {
    // let props = null;

    if (typeof window !== 'undefined') {
      props = {
        ...window.__PRELOADED_STATE__,
        ...props,
        cookies: document.cookie,
      };
    } else {
      if (typeof props.__PRELOADED_STATE__ !== 'object') {
        props = {
          ...props,
          ...JSON.parse(props.__PRELOADED_STATE__),
          cookies: props.cookies,
        };
      } else {
        props = {
          ...props,
          ...props.__PRELOADED_STATE__,
          PageTypeRenderer: props.PageTypeRenderer,
          cookies: props.cookies,
        };
      }
    }

    //Set up globals for platform application
    global.PWTPlatform = {};
    global.PWTPlatform.Translate = props.translate;
    global.PWTPlatform.IsEditMode = props.IsEditMode;
    global.PWTPlatform.EditAttributes = props.EditAttributes;

    return (
      <CookieContext cookiePro={props.CookiePro?.Enabled}>
        <WrappedComponent {...props} />
      </CookieContext>
    );
  };
  return Root;
};
