import React from 'react';
import UmoLogo from './umo';
import YoumoLogo from './youmo';

const Logo = ({ Site, logosr, link }) => {
  const siteLogo =
    Site === 'umo' ? <UmoLogo link={false} /> : <YoumoLogo link={false} />;

  return (
    <a className="c-header__logo" href={link}>
      <span className="show-for-sr" data-cy="ui-header-logosr">
        {logosr}
      </span>
      {siteLogo}
    </a>
  );
};

export { Logo as default, UmoLogo, YoumoLogo };
