import React from 'react';

const withToolbarButtonList = (WrappedComponent) => {
  return class ToolBtn extends React.Component {
    state = {
      isVisible: false,
    };

    handleVisible(e, id) {
      if (e) e.preventDefault();
      this.props.handleToolsVisibility(
        !this.state.isVisible,
        document.getElementById(id).offsetHeight
      );

      if (id === 'global-search') {
        setTimeout(() => {
          document.getElementById('globalsearch').focus();
        }, 420);
      }

      this.setState({
        isVisible: !this.state.isVisible,
      });
    }

    componentDidMount() {
      this.props.addToolComponent(this);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleVisible={(e, id) => this.handleVisible(e, id)}
        />
      );
    }
  };
};

export default withToolbarButtonList;
