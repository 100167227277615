import React, { useState, useEffect, useRef } from 'react';
import { log } from '../Helpers/helpers';
import { loadScript } from '../Base/Async';
import { onCookieChange } from '../Context/cookies';

const ReadingAidButton = (props) => {
  if (
    !props.ReadingAid?.EnableReadingAid &&
    !props.SiteHeader?.ReadingAid?.Enabled
  )
    return null;

  const [status, setStatus] = useState('PENDING');
  const retryCount = useRef(0);
  const timer = useRef(null);
  const isIE = () => false || !!document.documentMode;
  const isEdge = () => !isIE() && !!window.StyleMedia;

  const checkBA = () => {
    retryCount.current = retryCount.current + 1;
    clearTimeout(timer.current);

    if (retryCount.current > 20) {
      log('warn')('Did not load BrowseAloud.');
      setStatus('ERROR');
      return false;
    }
    if ('BrowseAloud' in window) {
      log('info')('BrowseAloud successfully loaded.');
      setStatus('DONE');
    } else {
      timer.current = setTimeout(checkBA, 1500);
    }
  };

  useEffect(() => {
    if (status === 'LOADED') {
      checkBA();
    } else if (!('BrowseAloud' in window) && status !== 'ERROR') {
      checkBrowser();
    }
    return () => clearTimeout(timer.current);
  }, [status]);

  const isCookieAllowed = onCookieChange('ReadingAid', ({ isAllowed }) => {
    // This will only run when cookie change.
    if (isAllowed) window.location.reload();
  });

  const checkBrowser = () => {
    if (typeof window === 'undefined') {
      return false;
    }
    if ((isIE() || isEdge()) && isCookieAllowed) {
      loadScript('pwt-ba261-script', '//www.browsealoud.com/plus/scripts/2.6.1/ba.js', () => {
        setStatus('LOADED');
      });
    } else if (isCookieAllowed) {
      // BrowseAloud Plus v3.0.5 (2020-07-17) Last working version
      loadScript('pwt-ba305-script', '//www.browsealoud.com/plus/scripts/3.0.5/ba.js', () => {
        setStatus('LOADED');
      });
    }
  };

  const togglePanels = () => {
    if ('toggleBar' in window) {
      window.toggleBar();
    }
  };

  return (
    <button
      disabled={status !== 'DONE'}
      onClick={() => togglePanels()}
      className={props.activateButtonClass}
      type="button"
      id={props.Id}
    >
      <span>{props.ReadingAid.ReadingAidButtonText}</span>
    </button>
  );
};

ReadingAidButton.defaultProps = {
  ButtonText: 'Aktivera Talande webb',
};

export default ReadingAidButton;
