import React from 'react';

export default class Async extends React.Component {
  state = {
    Pages: null,
    html: document.getElementById('async-pagetypes')
      ? document.getElementById('async-pagetypes').innerHTML
      : null,
  };

  componentDidMount() {
    switch (this.props.PageTypeName) {
      case 'ArticlePage':
        import('../../pages/article').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'StartPage':
        import('../../pages/start').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'FindCarePage':
        import('../../pages/findcare').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'SectionStartPage':
        import('../../pages/sectionstart').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'CollectionPage':
        import('../../pages/collection').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'FaqPage':
        import('../../pages/faq').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'StoryPage':
        import('../../pages/story').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'ForumPage':
        import('../../pages/forum').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'ContactCardPage':
        import('../../pages/contactcard').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;

      case 'SearchPage':
        import('../../pages/search').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'PodcastPage':
        import('../../pages/podcast').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'ThemePage':
        import('../../pages/theme').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'ErrorPage':
        import('../../pages/error').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
      case 'LanguagePage':
        import('../../pages/language').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
        case 'SelfTestPage':
          import('../../pages/selftest').then((page) => {
            this.setState({ Pages: page.default });
          });
          break;
      default:
        import('../../pages/start').then((page) => {
          this.setState({ Pages: page.default });
        });
        break;
    }
  }

  render() {
    if (!this.state.Pages)
      return (
        <div
          id="async-pagetypes"
          dangerouslySetInnerHTML={{ __html: this.state.html }}
        ></div>
      );

    return (
      <div id="async-pagetypes">
        <this.state.Pages {...this.props} />
      </div>
    );
  }
}
