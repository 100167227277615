import React, { Fragment } from 'react';
import withToolbarButtonList from '../Containers/withToolbarButtonList';
import ReadingAidPanel from './reading-aid-panel';

const ReadingAid = (props) => {
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      props.handleVisible(null, 'reading-aid');
    }
  };

  const { ReadingAid, ButtonSVG, toggleButtonClasses, isVisible } = props;

  if (!ReadingAid) return null;
  if (!ReadingAid.EnableReadingAid) return null;

  return (
    <Fragment>
      <a
        className={toggleButtonClasses}
        onClick={() => props.handleVisible(null, 'reading-aid')}
        onKeyPress={handleEnterPress}
        id="reading-aid-panel-btn"
        role="button"
        aria-controls="reading-aid"
        aria-labelledby="description-reading-aid"
        aria-expanded={isVisible}
        tabIndex="0"
      >
        {ButtonSVG}
        <span id="description-reading-aid">
          {props.Content.Translations && props.Content.Translations.listen}
        </span>
      </a>
      <ReadingAidPanel {...props} />
    </Fragment>
  );
};

export default withToolbarButtonList(ReadingAid);
