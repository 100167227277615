import * as Api from '../Api';
import queryString from 'qs';

export const loadMorePages = (query) => (dispatch, getState, callback) => {
  dispatch({
    type: 'LOADING',
    payload: getState(),
  });

  Api.search(query)
    .then((response) => response.json())
    .then((payload) => {
      if (typeof callback === 'function') {
        callback(payload, query);
      }
      dispatch({
        type: 'LOAD_MORE',
        key: query,
        payload: payload,
      });
    });
};

export const filterPages = (query) => (dispatch, getState, callback) => {
  dispatch({
    type: 'LOADING',
    payload: getState(),
  });

  Api.search(query)
    .then((response) => response.json())
    .then((payload) => {
      if (typeof callback === 'function') {
        callback(payload, query);
      }
      dispatch({
        type: 'FILTER',
        key: query,
        payload: payload,
      });
    });
};

export const autoComplete = (query, fallback) => (dispatch, getState) => {
  dispatch({
    type: 'LOADING',
    payload: getState(),
  });

  Api.autocomplete(query)
    .then((response) => {
      if (!response.ok && response.status !== 200) {
        throw new Error(response.status);
      }
      const qs = queryString.parse(query.substring(query.indexOf('?')), {
        ignoreQueryPrefix: true,
      });
      if (qs.q == getState().value) return response.json();

      return { aborted: true, valueReq: qs.q, valueState: getState().value };
    })
    .then((payload) => {
      //Only dispatch response from the last request
      //TODO: When better browsersupport for fetch: abort request instead

      if (payload && payload.aborted) return;

      return dispatch({
        type: 'AUTOCOMPLETE',
        key: query,
        payload: payload,
      });
    })
    .catch((e) => {
      const qs = queryString.parse(query.substring(query.indexOf('?')), {
        ignoreQueryPrefix: true,
      });
      if (fallback && fallback.length > 0 && qs.q) {
        const result = fallback
          .filter((item) => item.toLowerCase().startsWith(qs.q.toLowerCase()))
          .slice(0, 8);

        return dispatch({
          type: 'AUTOCOMPLETE',
          key: query,
          payload: result,
        });
      } else {
        console.error('Could not fetch autocomplete data from the server.');
        console.error(e.message);
        dispatch({
          type: 'ERROR',
          payload: e,
        });
      }
    });
};

export const fetchByQuery = (query) => (dispatch, getState, callback) => {
  console.log(query);
  dispatch({
    type: 'LOADING',
    payload: getState(),
  });

  Api.search(query)
    .then((response) => response.json())
    .then((payload) => {
      if (typeof callback === 'function') {
        callback(payload, query);
      }
      dispatch({
        type: 'SEARCH',
        key: query,
        payload: payload,
      });
    });
};

export const fetchByPost = (endPoint, data) => (dispatch, getState) => {
  dispatch({
    type: 'LOADING',
    payload: getState(),
  });

  Api.post(endPoint, data)
    .then((response) => response.json())
    .then((payload) =>
      dispatch({
        type: 'SEARCH',
        payload: payload,
      })
    );
};
